import { cn, ThemeColor } from '@/lib/utils';
import React from 'react';

const colorStyles: Record<ThemeColor, string> = {
  neutral: 'border-neutral-50 bg-neutral-20',
  primary: 'border-primary-border bg-primary-surface',
  danger: 'border-danger-border bg-danger-surface',
  warning: 'border-warning-border bg-warning-surface',
  success: 'border-success-border bg-success-surface',
  info: 'border-info-border bg-info-surface',
};

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: ThemeColor;
}

export const Alert: React.FC<AlertProps> = ({
  color = 'neutral',
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-md border-[1px] p-3',
        colorStyles[color],
        className
      )}
    >
      {children}
    </div>
  );
};
Alert.displayName = 'Alert';
