import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { ColumnDef } from '@tanstack/react-table';

interface Attendee {
  id: string;
  ticketId: string;
  name: string;
  purchaseDate: Date;
  event: string;
  ticket: string;
  attended: boolean;
  attendTime?: Date;
}

const columns: ColumnDef<Attendee>[] = [
  {
    header: '#',
    accessorKey: 'id',
  },
  {
    header: 'No. Tiket & Nama peserta',
    cell: ({ row }) => {
      const ticketId = row.original.ticketId;
      const name = row.original.name;

      return (
        <>
          <div>{ticketId}</div>
          <div className='text-neutral-70'>{name}</div>
        </>
      );
    },
  },
  {
    accessorKey: 'purchaseDate',
    header: 'Tanggal beli tiket',
    cell: ({ row }) => {
      const purchaseDate = row.original.purchaseDate;
      return (
        <>
          <span>
            {purchaseDate.toLocaleDateString('id', {
              dateStyle: 'medium',
            })}
          </span>
          <span className='ml-2 text-neutral-70'>
            {purchaseDate.toLocaleTimeString('id', {
              timeStyle: 'medium',
            })}
          </span>
        </>
      );
    },
  },
  {
    header: 'Event - Tiket',
    cell: ({ row }) => {
      return (
        <>
          {row.original.event} -{' '}
          <span className='text-info'>{row.original.ticket}</span>
        </>
      );
    },
  },
  {
    header: 'Status',
    cell: ({ row }) => {
      const attended = row.original.attended;
      return attended ? (
        <Tag color='success'>Sudah absen</Tag>
      ) : (
        <Tag color='danger'>Belum absen</Tag>
      );
    },
  },
  {
    accessorKey: 'attendTime',
    header: 'Absen',
    cell: ({ row }) => {
      return (
        <>
          {row.original.attendTime
            ? row.original.attendTime.toLocaleTimeString('id', {
                timeStyle: 'medium',
              })
            : '-'}
        </>
      );
    },
  },
];

const tempData: Attendee[] = [
  {
    id: '1',
    ticketId: '#TKEVNT23124',
    name: 'Agung Krisna',
    purchaseDate: new Date('2023-04-14T19:02:48+0800'),
    event: 'Kramat Unmas',
    ticket: 'Reguler',
    attended: true,
    attendTime: new Date(),
  },
];

export function OrgEventDetailAttendee() {
  return (
    <>
      <DataTable
        name='Daftar Peserta'
        noun='peserta'
        columns={columns}
        data={tempData}
      />
    </>
  );
}
