import appStoreLogo from "@/routes/org/qrcode/_assets/appstore.png";
import playStoreLogo from "@/routes/org/qrcode/_assets/playstore.png";
import React from "react";

export function QrCodeDashboard() {
  return (
    <>
      <div className={'flex justify-center align-middle items-center my-auto'}>
        <div>
          <div className={"grid grid-cols-2 mb-4 gap-2"}>
            <img src={appStoreLogo} className='rounded-md w-64 h-full' alt='' />
            <img src={playStoreLogo} className='rounded-md w-64 h-full' alt='' />
          </div>
          <h1 className='text-center mb-3 text-lg font-medium'>Silakan download aplikasi untuk scan tiket</h1>
        </div>
      </div>
    </>
  );
}
