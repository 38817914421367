import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';

import styles from './Input.module.css';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leftDeco?: React.ReactNode;
  rightDeco?: React.ReactNode;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      readOnly,
      disabled,
      leftDeco,
      rightDeco,
      leftIcon,
      rightIcon,
      className,
      ...props
    },
    ref
  ) => {
    const decoTextStyles = cn(
      'shrink-0 border-neutral-50 flex-grow-0 py-2 text-xs font-normal p-2',
      readOnly || disabled
        ? 'text-neutral-60 bg-neutral-40'
        : 'text-neutral-80 bg-neutral-10'
    );

    const iconStyles =
      'absolute top-1/2 h-[14px] w-[14px] -translate-y-1/2 text-neutral-90';

    return (
      <div
        className={cn(
          'flex w-full [&>*]:rounded-md [&>*]:border-[1px]',
          styles.inputBorders,
          className
        )}
      >
        {leftDeco ? (
          <div className={decoTextStyles}>{leftDeco}</div>
        ) : undefined}

        <div
          className={cn(
            styles.inputWrapper,
            'relative shrink grow bg-neutral-10',
            disabled ? 'border-neutral-50 bg-neutral-40 text-neutral-60' : ''
          )}
        >
          {leftIcon ? (
            <Slot
              className={cn(
                iconStyles,
                'left-[12px]',
                leftIcon.props.className
              )}
            >
              {leftIcon}
            </Slot>
          ) : undefined}

          <input
            type={type}
            className={cn(
              'block w-full rounded-md bg-transparent py-2 text-xs font-normal placeholder:text-neutral-70',
              leftIcon ? 'pl-[34px]' : 'pl-3',
              rightIcon ? 'pr-[34px]' : 'pr-3'
            )}
            ref={ref}
            readOnly={readOnly}
            disabled={disabled}
            {...props}
          />

          {rightIcon ? (
            <Slot
              className={cn(
                iconStyles,
                'right-[12px]',
                rightIcon.props.className
              )}
            >
              {rightIcon}
            </Slot>
          ) : undefined}
        </div>

        {rightDeco ? (
          <div className={decoTextStyles}>{rightDeco}</div>
        ) : undefined}
      </div>
    );
  }
);
Input.displayName = 'Input';
