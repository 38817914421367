import { Alert } from '@/components/alert';
import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { NamedTable } from '@/components/table/namedtable';
import { Tag } from '@/components/tag';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  ExclamationCircleIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';
import { DashboardCard } from './_card';
import { DashboardEventCard } from './_eventcard';

export function OrgDashboard() {
  return (
    <>
      <Alert color='warning'>
        <ExclamationCircleIcon className='h-5 w-5 flex-shrink-0 text-warning' />
        <p className='h-fit flex-grow text-sm font-medium'>
          Profil Anda belum lengkap! Harap lengkapi profil terlebih dahulu.
        </p>
        <Button
          className='flex-shrink-0'
          variant='warning'
          size='sm'
          element={'link'}
          to={'/profile/info'}
          leftIcon={<PencilSquareIcon />}
        >
          Lengkapi profil
        </Button>
      </Alert>

      <div>
        <h1 className='mb-3 text-lg font-medium'>Event yang sedang berjalan</h1>
        <div className='flex flex-col gap-6 pb-2 sm:flex-row sm:overflow-x-auto'>
          <DashboardEventCard />
          <DashboardEventCard />
        </div>
      </div>

      <HSeparator />

      <div className='grid grid-cols-2 gap-2 pb-2 sm:gap-6 md:grid-cols-3 xl:grid-cols-4'>
        <DashboardCard
          variant='yellow'
          name='Event'
          topLabel={{ primary: '3', secondary: 'event' }}
        />
        <DashboardCard
          variant='blue'
          name='Pemasukan'
          topLabel={{ primary: 'Rp18.500.000' }}
          bottomLabel={{
            primary: '17%',
            secondary: 'lebih banyak dari kemarin',
            icon: ArrowUpCircleIcon,
          }}
        />
        <DashboardCard
          variant='white'
          name='Transaksi'
          topLabel={{ primary: '8', secondary: 'transaksi' }}
          bottomLabel={{
            primary: '24',
            secondary: 'lebih sedikit dari kemarin',
            icon: ArrowDownCircleIcon,
          }}
        />
        <DashboardCard
          variant='whiteAlt'
          name='Pengunjung'
          topLabel={{ primary: '112', secondary: 'pengunjung' }}
          bottomLabel={{
            primary: '55',
            secondary: 'lebih banyak dari kemarin',
            icon: ArrowUpCircleIcon,
          }}
        />
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6 lg:flex-row lg:items-start'>
        <NamedTable
          title='Riwayat Transaksi'
          ctaButton={
            <Button
              variant='neutral'
              size='sm'
              leftIcon={<EyeIcon />}
              element='link'
              to='../transaction'
            >
              Lihat semua
            </Button>
          }
          // ctaIcon={EyeIcon}
          className='flex-grow'
        >
          <thead>
            <tr>
              <th>Nama</th>
              <th>Tanggal</th>
              <th>Event - Tiket</th>
              <th>Nominal transaksi</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Agung Krisna</td>
              <td>14 April 2023</td>
              <td>
                Kramat Unmas - <span className='text-info'>Reguler</span>{' '}
                <span className='text-neutral-70'>(x2)</span>
              </td>
              <td className='text-danger'>Rp60.000</td>
              <td>
                <Tag color='warning'>Belum bayar</Tag>
              </td>
            </tr>
            <tr>
              <td>Bismana Kori</td>
              <td>13 April 2023</td>
              <td>
                Kramat Unmas - <span className='text-info'>Majesty</span>{' '}
                <span className='text-neutral-70'>(x1)</span>
              </td>
              <td className='text-danger'>Rp150.000</td>
              <td>
                <Tag color='success'>Sudah bayar</Tag>
              </td>
            </tr>
            <tr>
              <td>Faisal Andyan</td>
              <td>13 April 2023</td>
              <td>
                Kramat Unmas - <span className='text-info'>Reguler</span>{' '}
                <span className='text-neutral-70'>(x1)</span>
              </td>
              <td className='text-danger'>Rp50.000</td>
              <td>
                <Tag color='danger'>Kedaluwarsa</Tag>
              </td>
            </tr>
          </tbody>
        </NamedTable>

        <HSeparator className='block lg:hidden' />

        <NamedTable
          title='Riwayat Pengunjung'
          ctaButton={undefined}
          className='lg:basis-[500px]'
        >
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Jumlah pengunjung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12 April 2023</td>
              <td>112</td>
            </tr>
            <tr>
              <td>11 April 2023</td>
              <td>64</td>
            </tr>
            <tr>
              <td>10 April 2023</td>
              <td>123</td>
            </tr>
            <tr>
              <td>9 April 2023</td>
              <td>89</td>
            </tr>
          </tbody>
        </NamedTable>
      </div>
    </>
  );
}
