import { DataViewBase } from '@/components/dataview';
import { Table } from '@/components/table';
import { Tag } from '@/components/tag';

import bcaLogo from '../../_payment/bca.svg';

export function OrgCommerceWithdrawal() {
  return (
    <>
      <DataViewBase name='Riwayat Penarikan' noun='transaksi'>
        {/* TODO: separate empty list graphic component */}
        {/* <div className='flex flex-col items-center gap-6 p-6'>
          <Image
            src='/static/empty-graphic.svg'
            width={300}
            height={247}
            alt=''
          />
          <div className='flex flex-col items-center gap-3'>
            <p className='text-2xl font-medium'>Belum ada event</p>
            <p className='text-center text-base text-neutral-70'>
              Saat ini belum terdapat event yang tercatat.
            </p>
          </div>
          <Button elementType='link' href='dashboard' Icon={ChartPieIcon}>
            Kembali ke dashboard
          </Button>
        </div> */}
        <Table className='overflow-x-auto whitespace-nowrap'>
          <thead>
            <tr>
              <th>#</th>
              <th>Tanggal</th>
              <th>Nominal</th>
              <th>Rekening tujuan</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>04/04/2023</td>
              <td className='text-danger-main'>Rp60.000</td>
              <td className='flex items-center justify-start gap-2'>
                <img src={bcaLogo} className='h-full rounded-md' alt='' />
                <div>
                  <div>Arif Rahman</div>
                  <div className='font-normal text-neutral-70'>50118274612</div>
                </div>
              </td>
              <td>
                <Tag color='warning'>Pending</Tag>
              </td>
            </tr>
          </tbody>
        </Table>
      </DataViewBase>
    </>
  );
}
