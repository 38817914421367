import {FormLabel} from "@/components/form/formlabel";
import {Checkbox} from "@/components/form/checkbox";
import {Button} from "@/components/button";
import {NoSymbolIcon} from "@heroicons/react/24/outline";
import {DocumentCheckIcon} from "@heroicons/react/24/solid";
import {Modal} from "@/components/modal";
import React from "react";


export interface SetupModalBase
    extends React.HTMLAttributes<HTMLDivElement> {
    setupModal: boolean;
    setSetupModal?: any;
}

export const OtsSetupModal: React.FC<SetupModalBase> = ({setupModal = false, setSetupModal}) => {
    return(
        <Modal
            show={setupModal}
            onRequestClose={() => setSetupModal(false)}
            title='Atur data ots'
            body={
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-3'>
                        <p className='text-sm font-medium text-neutral-70'>Data yang dibutuhkan</p>
                        <div className='flex flex-wrap gap-6'>
                            <FormLabel label='Nama' inline>
                                <Checkbox />
                            </FormLabel>

                            <FormLabel label='Email' inline>
                                <Checkbox />
                            </FormLabel>

                            <FormLabel label='Whatsapp' inline>
                                <Checkbox checked={true} disabled />
                            </FormLabel>
                        </div>
                    </div>
                </div>
            }
            footer={
                <div className='flex justify-end gap-3'>
                    <Button
                        variant='neutral'
                        onClick={() => setSetupModal(false)}
                        leftIcon={<NoSymbolIcon />}
                    >
                        Batal
                    </Button>
                    <Button
                        onClick={() => {
                            setSetupModal(false);
                        }}
                        leftIcon={<DocumentCheckIcon />}
                    >
                        Simpan
                    </Button>
                </div>
            }
        />
    )
}

OtsSetupModal.displayName = 'OtsSetupModal';
