import { Button } from '@/components/button';
import { FormLabel } from '@/components/form/formlabel';
import { Input } from '@/components/form/input';
import { Modal } from '@/components/modal';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import {
    ArrowDownOnSquareIcon, CheckIcon,
    DocumentCheckIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import { DashboardCard } from '../dashboard/_card';
import { SectionItem, SectionTabs } from '../_nav/section';
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/form/select";

export function OrgCommerceLayout() {
  const location = useLocation();

  const pathArray = location.pathname.split('/').slice(2);
  const section = pathArray[0];

  const [withdrawModal, setWithdrawModal] = useState(false);

  return !section ? (
      <Navigate to='payment' />
  ) : (
    <>
      <Modal
        show={withdrawModal}
        onRequestClose={() => setWithdrawModal(false)}
        width='medium'
        title='Tarik saldo'
        body={
          <div className='flex flex-col gap-4'>
            <FormLabel
              label='Jumlah saldo yang ingin ditarik'
              helperText='Minimal penarikan Rp100.000'
              required
            >
              <Input leftDeco='Rp' type='text' required />
            </FormLabel>

              <FormLabel label='Pilih bank' required>
                  <Select>
                      <SelectTrigger>
                          <SelectValue defaultValue='others' />
                      </SelectTrigger>
                      <SelectContent>
                          <SelectGroup>
                              <SelectItem value='temp1'>BCA</SelectItem>
                              <SelectItem value='temp2'>BNI</SelectItem>
                          </SelectGroup>
                      </SelectContent>
                  </Select>
              </FormLabel>

            <FormLabel label='Pilih rekening tujuan' successText='I KM TR****NA A***A PU**RA' required>
              <Input type='text' required rightIcon={<CheckIcon />} />
            </FormLabel>
          </div>
        }
        footer={
          <div className='flex justify-end gap-3'>
            <Button
              variant='neutral'
              onClick={() => setWithdrawModal(false)}
              leftIcon={<NoSymbolIcon />}
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                setWithdrawModal(false);
              }}
              leftIcon={<DocumentCheckIcon />}
            >
              Simpan
            </Button>
          </div>
        }
      />
      <div className='flex gap-6'>
        <DashboardCard
          variant='white'
          name='Total saldo penjualan'
          topLabel={{ primary: 'Rp 0' }}
          className='w-[268px]'
        />
        <DashboardCard
          variant='whiteAlt'
          name='Saldo yang dapat ditarik'
          topLabel={{ primary: 'Rp 0' }}
          bottomLabel={{
            primary: (
              <Button
                size='sm'
                leftIcon={<ArrowDownOnSquareIcon />}
                onClick={() => setWithdrawModal(true)}
              >
                Tarik saldo
              </Button>
            ),
          }}
          className='w-[268px]'
        />
      </div>
      <SectionTabs>
        <SectionItem
          id='payment'
          name='Riwayat pemasukan'
          isCurrent={section === 'payment'}
        />
        <SectionItem
          id='withdrawal'
          name='Riwayat penarikan'
          isCurrent={section === 'withdrawal'}
        />
      </SectionTabs>
      <Outlet />
    </>
  );
}
