import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import checkIcon from "./_assets/check.png"
import bcaIcon from "@/routes/org/_payment/bca.svg"

export const PaymentDetail = () => {
  const paymentStatus = "pending";

  return (
    <div>
      {paymentStatus === "pending" && <PaymentMethod />}
      {paymentStatus === "success" && <PaymentStatus />}
    </div>
  )
}

export const PaymentStatus = () => {
  return (
<div>
                        
                        <div className="flex justify-center">
                        <img
                                            className='w-1/2 shrink-0'
                                            src={checkIcon}
                                            alt=''
                                            />
                                            </div>
                        <p className="text-center text-green-500 mb-2">Pembayaran berhasil</p>
                        </div>
  )
}

export const PaymentMethod = () => {
  const paymentMethod = "VA";

  return (
    <div>
                        
                    <p className="text-center mb-2">Silakan bayar ke {paymentMethod} berikut dalam waktu</p>
                    <div>
                        <p className="text-center text-2xl font-bold mb-4 animate-pulse">15:00</p>
                    </div>
                    <div className="flex justify-center">
                      {paymentMethod === "QRIS" && <img
                                        className='w-1/2 shrink-0'
                                        src={'https://docs.lightburnsoftware.com/img/QRCode/ExampleCode.png'}
                                        alt=''
                                        />}
                                                       {paymentMethod === "VA" && 
                                                       <div>
                                                        <div className="flex gap-1 mb-4">
                                                        <span className="text-lg font-semibold text-red-500">009128192378972</span>
                                                        <DocumentDuplicateIcon className="w-5 text-red-500 cursor-pointer" />
                                                        </div>
                                                        <div className="flex justify-center">
                                                        <img
                                        className='w-1/2 shrink-0'
                                        src={bcaIcon}
                                        alt=''
                                        />
                                                        </div>
                                                        </div>
                                                       }
                                        </div>
                    </div>
  )
}