import { cn } from '@/lib/utils';
import { ButtonProps } from '../button';
import { Table } from './table';

export interface NamedTableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  title: string;
  ctaButton: React.ReactElement<ButtonProps>|undefined;
}

export const NamedTable: React.FC<NamedTableProps> = ({
  title,
  ctaButton,
  children,
  className,
}) => {
  return (
    <div className={cn('rounded-md border-[1px] border-neutral-40', className)}>
      <div className='flex border-b-[1px] border-neutral-40 p-4'>
        <p className='mr-2 flex-grow text-lg font-medium'>{title}</p>
        {ctaButton}
      </div>
      <div className='overflow-x-auto'>
        <Table
          className='whitespace-nowrap md:whitespace-normal'
          noBorder={true}
        >
          {children}
        </Table>
      </div>
    </div>
  );
};
NamedTable.displayName = 'NamedTable';
