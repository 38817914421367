import { cn } from '@/lib/utils';
import React from 'react';

export interface FormLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  htmlFor?: string;
  helperText?: string;
  errorText?: string;
  successText?: string;
  required?: boolean;
  inline?: boolean;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  label,
  htmlFor,
  helperText,
  errorText,
  successText,
  required = false,
  inline = false,
  children,
  className,
}) => {
  const labelElement = label ? (
    <label
      htmlFor={htmlFor}
      className='block text-xs font-medium text-neutral-90'
    >
      {label}
      {required ? <span className='ml-1 text-danger'>*</span> : undefined}
    </label>
  ) : undefined;

  return label || helperText ? (
    <div className={cn('flex flex-col gap-[6px]', className)}>
      {inline ? (
        label ? (
          <div className='flex items-center gap-2'>
            {children}
            <label
              htmlFor={htmlFor}
              className='block shrink-0 text-sm font-medium'
            >
              {label}
              {required ? (
                <span className='ml-1 shrink grow text-danger'>*</span>
              ) : undefined}
            </label>
          </div>
        ) : (
          children
        )
      ) : (
        <>
          {label ? (
            <label
              htmlFor={htmlFor}
              className='block text-xs font-medium text-neutral-90'
            >
              {label}
              {required ? (
                <span className='ml-1 text-danger'>*</span>
              ) : undefined}
            </label>
          ) : undefined}
          {children}
        </>
      )}

      {helperText ? (
        <p className='block text-xs text-neutral-70'>{helperText}</p>
      ) : undefined}
      {errorText ? (
        <p className='block text-xs text-danger'>{errorText}</p>
      ) : undefined}
      {successText ? (
          <p className='block text-xs text-success'>{successText}</p>
      ) : undefined}
    </div>
  ) : (
    <div className={`relative ${className}`}>{children}</div>
  );
};
FormLabel.displayName = 'FormLabel';
