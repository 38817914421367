import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DataViewBase, DataViewBaseProps } from '../dataview';
import { Table } from './table';

export interface DataTableProps<TData, TValue> extends DataViewBaseProps {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  additionalRows?: React.ReactNode;
}

export const DataTable = <TData, TValue>({
  name,
  noun,
  ctaButton,
  columns,
  data,
  pages = 1,
  currentPage = 0,
  additionalRows,
  onPageRequest,
}: DataTableProps<TData, TValue>) => {
  const baseProps: DataViewBaseProps = {
    name,
    noun,
    ctaButton,
    pages,
    currentPage,
    onPageRequest,
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <DataViewBase {...baseProps}>
      <Table className='overflow-x-auto whitespace-nowrap'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className='h-24 text-center'>
                No results.
              </td>
            </tr>
          )}
          {additionalRows}
        </tbody>
      </Table>
    </DataViewBase>
  );
};
DataTable.displayName = 'DataTable';
