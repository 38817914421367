import React from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import 'primeicons/primeicons.css';

export function Register() {
    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Selamat datang! 👋🏻</div>
            <p className={"text-400 mb-4"}>Buat akun biar bisa kamu segera jual tiket</p>
            <form>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Nama Penyelenggara' required>
                            <Input type='text' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Email' required>
                            <Input type='email' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>

                    <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            <CheckIcon className={'w-8 mr-2'}/><small>Dengan mendaftar, saya berarti telah menyetujui
                            seluruh <span className={"underline font-semibold"}>syarat dan ketentuan</span> yang berlaku</small>
                        </div>
                    </div>

                    <div>
                        <Button
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Daftar</Button>
                        <Button
                            variant={'neutral'}
                            look={'outline'}
                            className={'w-full mt-2'}
                            color='neutral'
                            leftIcon={<i className="pi pi-google"></i>}
                        >Daftar dengan Google</Button>
                    </div>
                </div>
            </form>
            <div className={"mt-4 text-center"}>
                <span className="text-600 font-medium line-height-3">Sudah punya akun?</span>
                {' '}<a href={'login'} className={'underline font-semibold'}>Login yuk!</a>
            </div>
        </>
    )
}
