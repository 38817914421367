import {Button} from '@/components/button';
import {DropdownMenu, DropdownMenuTrigger} from '@/components/dropdown';
import {Dropzone} from '@/components/form/dropzone';
import {FormLabel} from '@/components/form/formlabel';
import {Modal} from '@/components/modal';
import {ArrowPathIcon, NoSymbolIcon} from '@heroicons/react/24/outline';
import {DocumentCheckIcon, TagIcon} from '@heroicons/react/24/solid';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NewEventFlowContext} from '../state';

import qrisLogo from '../../../_payment/qris.png';
import {Input} from "@/components/form/input";

export function OrgEventNewStep3() {
    const navigate = useNavigate();
    const {setOnPublishClicked} = useContext(NewEventFlowContext);

    const [payModal, setPayModal] = useState(false);

    useEffect(() => {
        if (setOnPublishClicked) {
            setOnPublishClicked(() => () => {
                setPayModal(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onConfirmClicked() {
        navigate('../checkout/0');
    }

    return (
        <>
            <Modal
                show={payModal}
                onRequestClose={() => setPayModal(false)}
                width='medium'
                title='Pilih metode pembayaran paket'
                body={
                    <div className='flex flex-col gap-3'>
                        <p className='text-sm font-medium'>Paket terpilih</p>
                        <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3'>
                            <TagIcon className='h-[20px] w-[20px] text-primary'/>
                            <p className='text-sm font-medium'>Paket Rektor - Rp400.000</p>
                        </div>

                        <FormLabel label='Pilih metode pembayaran'>
                            <DropdownMenu>
                                <DropdownMenuTrigger>
                                    <div
                                        className='flex items-center gap-2 rounded-md border-[1px] border-neutral-40 p-3 text-left'>
                                        <img
                                            className='h-[36px] w-[51px] shrink-0'
                                            src={qrisLogo}
                                            alt=''
                                        />
                                        <p className='grow text-xs font-medium'>QRIS</p>
                                        <Button
                                            look='outline'
                                            size='sm'
                                            rightIcon={<ArrowPathIcon/>}
                                            className='shrink-0'
                                        >
                                            Ganti
                                        </Button>
                                    </div>
                                </DropdownMenuTrigger>
                            </DropdownMenu>
                        </FormLabel>
                    </div>
                }
                footer={
                    <div className='flex justify-end gap-3'>
                        <Button
                            variant='neutral'
                            onClick={() => setPayModal(false)}
                            leftIcon={<NoSymbolIcon/>}
                        >
                            Batal
                        </Button>
                        <Button onClick={onConfirmClicked} leftIcon={<DocumentCheckIcon/>}>
                            Konfirmasi pilihan
                        </Button>
                    </div>
                }
            />

            <div className='md:flex justify-stretch gap-2'>
                <div className='flex-grow md:mb-0 mb-3'>
                    <FormLabel label='KTP penanggung jawab' required>
                        <Dropzone maxFiles={1}/>
                    </FormLabel>
                </div>
              <div className='flex-grow'>
                <div className={'mb-3 flex justify-stretch gap-2'}>
                    <FormLabel label='Nama sesuai KTP' className='flex-grow' required>
                        <Input type='text' required/>
                    </FormLabel>
                    <FormLabel label='No. KTP' className='flex-grow' required>
                        <Input type='text' required/>
                    </FormLabel>
                </div>
                <FormLabel label='Alamat sesuai KTP' className='flex-grow' required>
                  <Input type='text' required/>
                </FormLabel>
              </div>
            </div>

            {/* TODO: add PDF support for the last two */}

            <FormLabel label='Izin penyelenggara event' required>
                <Dropzone maxFiles={1}/>
            </FormLabel>
        </>
    );
}
