import { BreadcrumbRouteTree } from '@/components/breadcrumb';
import { HSeparator } from '@/components/separator';
import { cn } from '@/lib/utils';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from './menuitem';

import styles from './SideNav.module.css';

export interface SideNavProps extends React.HTMLAttributes<HTMLDivElement> {
  routeTree: BreadcrumbRouteTree;
  currentRoute: string;
  show: boolean;
  onRequestClose?: () => void;
}

export const SideNav: React.FC<SideNavProps> = ({
  routeTree,
  currentRoute,
  show,
  onRequestClose,
}) => {
  const navStyle = cn(
    styles.navWrapper,
    show ? styles.isOpen : undefined,
    'fixed z-10 flex h-screen w-screen flex-col gap-8 bg-neutral-20 px-[12px] pb-[24px] pt-[16px] shadow-lg sm:w-80 lg:static lg:z-0 lg:w-full lg:shrink-0 lg:grow-0 lg:basis-60 lg:shadow-none'
  );

  return (
    <div className={navStyle}>
      <button
        className='self-start rounded-[6px] border-[1px] border-neutral-40 p-[4px] lg:hidden'
        onClick={onRequestClose}
      >
        <XMarkIcon className='h-4 w-4' />
      </button>
      <img
        src='/logo.png'
        alt='Logo'
        width={112}
        height={50}
        className='h-[50px] w-full object-contain'
      />
        <div className='flex flex-grow flex-col gap-3 overflow-scroll'>
        {Object.entries(routeTree).map(([key, value]) => {
          return (
            <Fragment key={key}>
              <Link to={value.path ?? `/${key}` ?? ''}>
                <MenuItem active={key === currentRoute} Icon={value.icon}>
                  {value.name}
                </MenuItem>
              </Link>
              {value.break ? <HSeparator /> : undefined}
            </Fragment>
          );
        })}
      </div>
      <p className='text-center text-sm text-neutral-70'>
        &copy; tokoevent.com
      </p>
    </div>
  );
};
SideNav.displayName = 'SideNav';
