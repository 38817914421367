import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbRouteDef {
  name: string;
  path?: string;
  icon?: any;
  break?: boolean;
  hidden?: boolean;
  children?: BreadcrumbRouteTree;
}

export type BreadcrumbRouteTree = { [key: string]: BreadcrumbRouteDef };

export interface BreadcrumbProps extends React.HTMLAttributes<HTMLDivElement> {
  routeTree: BreadcrumbRouteTree;
  basePath: string;
  pathArray: Array<string>;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  routeTree,
  basePath,
  pathArray,
}) => {
  let mappedRouteDefs: Array<{ id: string; name: string; pos: number }> = [];
  let routeTreeIter: BreadcrumbRouteTree = routeTree;

  pathArray.every((segment, i) => {
    const routeDef = routeTreeIter[segment] || routeTreeIter['*'];
    if (routeDef) {
      if (routeDef.children) {
        routeTreeIter = routeDef.children;
      }
      if (!routeDef.hidden) {
        mappedRouteDefs.push({ id: segment, name: routeDef.name, pos: i });
      }
      return true;
    } else {
      return false;
    }
  });

  const children = mappedRouteDefs.map((value, index) => {
    return (
      <Fragment key={value.id}>
        {index > 0 ? (
          <ChevronRightIcon className='h-4 w-4 text-neutral-90' />
        ) : undefined}
        {index >= mappedRouteDefs.length - 1 ? (
          <p className='text-sm font-semibold text-info'>{value.name}</p>
        ) : (
          <Link
            to={`${basePath}${pathArray.slice(0, value.pos + 1).join('/')}`}
            className='text-sm font-semibold text-neutral-70 hover:underline'
          >
            {value.name}
          </Link>
        )}
      </Fragment>
    );
  });

  return children.length > 0 ? (
    <div className='flex items-center gap-2'>
      <ArrowLeftIcon className='h-4 w-4 text-neutral-90' />
      {children}
    </div>
  ) : null;
};
Breadcrumb.displayName = 'Breadcrumb';
