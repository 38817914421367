import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import React from "react";
import {Button} from "@/components/button";
import {DocumentCheckIcon} from "@heroicons/react/24/solid";

export function OrgProfileSecurity() {
    return (
        <>
            <div className={'md:flex justify-center mx-4 md:mx-0 mt-8'}>
                <div className={'md:w-2/3'}>
                    <div className="mb-3">
                        <FormLabel label='Password Lama' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password Baru' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Konfirmasi Password Baru' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>
                    <div className='flex justify-end gap-3'>
                        <Button
                            className={'w-full md:w-max mt-4'}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
