import { PaymentCard } from '../_payment';

export default function OrgEventNewCheckout() {
  return (
    <div className='flex flex-col gap-9'>
      <h1 className='text-center text-3xl font-medium'>
        Terima kasih sudah memilih <b>“Paket Rektor”</b>
      </h1>
      <PaymentCard className='mx-auto max-w-[600px]' />
    </div>
  );
}
