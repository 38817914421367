import { Alert } from '@/components/alert';
import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { cn } from '@/lib/utils';
import {
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DocumentCheckIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PlanListModal } from '../_plan';
import { NewEventFlowContext } from './state';

// TODO: implement saving event draft to local storage
//       preferrably when event models are implemented

const StepIndicator: React.FC<{ label: string; current: boolean }> = ({
  label,
  current,
}) => {
  return (
    <div
      className={`max-line-1 min-w-0 flex-shrink flex-grow ${
        current ? 'basis-10' : 'basis-0'
      }`}
    >
      <p
        className={`max-line-1 mb-2 h-[24px] text-base ${
          current
            ? 'font-medium text-neutral-100'
            : 'font-normal text-neutral-70'
        }`}
      >
        {label}
      </p>
      <div
        className={`min-h-[4px] ${current ? 'bg-primary' : 'bg-neutral-40'}`}
      />
    </div>
  );
};

const steps = 3;

export function OrgEventNewLayout() {
  // const navigate = useNavigate();
  const location = useLocation();

  const [planModal, setPlanModal] = useState(false);
  const [onPublishClicked, setOnPublishClicked] = useState(() => () => {});

  const stepRoute = location.pathname.split('/').at(-1);
  const stepIdx = parseInt(stepRoute?.at(-1) || '') - 1;

  const onPlanClicked = (plan: string) => {
    setPlanModal(false);
    // router.push(`?plan=${plan}`);
  };

  return (
    <>
      <NewEventFlowContext.Provider
        value={{ setPlanModal, setOnPublishClicked }}
      >
        <div className='flex w-full max-w-[900px] flex-col gap-4 self-center lg:gap-6'>
          <h2 className='text-center text-lg font-medium'>Buat Event</h2>
          <div className='flex justify-stretch gap-2'>
            <StepIndicator label='1. Informasi acara' current={true} />
            <StepIndicator label='2. Tiket' current={stepIdx >= 1} />
            <StepIndicator label='3. Penanggung jawab' current={stepIdx >= 2} />
          </div>

          <Outlet />

          <HSeparator />
          <div
            className={cn(
              stepIdx < steps - 1 ? 'flex-row' : 'flex-col-reverse md:flex-row',
              'flex gap-4'
            )}
          >
            {stepIdx !== 0 ? (
              <Button
                color='neutral'
                element='link'
                to={`step-${stepIdx}`}
                leftIcon={<ChevronLeftIcon />}
                className='md:mr-auto'
              >
                Sebelumnya
              </Button>
            ) : undefined}
            {stepIdx < steps - 1 ? (
              <Button
                element='link'
                to={`step-${stepIdx + 2}`}
                rightIcon={<ChevronRightIcon />}
                className='ml-auto'
              >
                Selanjutnya
              </Button>
            ) : (
              <>
                <Button look='outline' leftIcon={<DocumentTextIcon />}>
                  Simpan sebagai draf
                </Button>
                <Button
                  onClick={onPublishClicked}
                  leftIcon={<DocumentCheckIcon />}
                >
                  Simpan dan publikasi
                </Button>
              </>
            )}
          </div>
        </div>
      </NewEventFlowContext.Provider>
    </>
  );
}
