import { Button } from '@/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/dropdown';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { ThemeColor } from '@/lib/utils';
import { idrFormatter } from '@/lib/utils/currency';
import { EllipsisHorizontalIcon, EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

type TransactionStatus = 'paid' | 'unpaid' | 'expired';

interface Transaction {
  id: string;
  name: string;
  transactionDate: Date;
  event: string;
  nominal: number;
  status: TransactionStatus;
}

const tagProps: Record<TransactionStatus, { color: ThemeColor; text: string }> =
  {
    paid: { color: 'success', text: 'Sudah bayar' },
    unpaid: { color: 'warning', text: 'Belum bayar' },
    expired: { color: 'danger', text: 'Kedaluwarsa' },
  };

const columns: ColumnDef<Transaction>[] = [
  {
    accessorKey: 'id',
    header: '#',
  },
  {
    accessorKey: 'name',
    header: 'Nama',
  },
  {
    accessorKey: 'transactionDate',
    header: 'Tanggal',
    cell: ({ row }) => {
      const transactionDate = row.original.transactionDate;
      return transactionDate.toLocaleDateString('id', {
        dateStyle: 'medium',
      });
    },
  },
  {
    accessorKey: 'event',
    header: 'Event - Tiket',
  },
  {
    accessorKey: 'nominal',
    header: 'Nominal',
    cell: ({ row }) => {
      const nominal = row.original.nominal;
      return (
        <span className='text-danger'>{idrFormatter.format(nominal)}</span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      const { color, text } = tagProps[status as TransactionStatus];
      return <Tag color={color}>{text}</Tag>;
    },
  },
  {
    header: 'Aksi',
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              size='sm'
              variant='neutral'
              leftIcon={<EllipsisHorizontalIcon />}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Link to='0'>
              <DropdownMenuItem>
                <EyeIcon className='mr-2 h-4 w-4' />
                <span>Detail</span>
              </DropdownMenuItem>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

const tempData: Transaction[] = [
  {
    id: '1',
    name: 'Agung Krisna',
    transactionDate: new Date('2023-04-14T00:00:00+0800'),
    event: 'Kramat Unmas - Reguler (x2)',
    nominal: 60000,
    status: 'unpaid',
  },
];

export function OrgTransaction() {
  return (
    <>
      <DataTable
        name='Riwayat Transaksi'
        noun='transaksi'
        columns={columns}
        data={tempData}
      />
    </>
  );
}
