import {Avatar} from "primereact/avatar";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import React, {useState} from "react";
import {Button} from "@/components/button";
import {DocumentCheckIcon} from "@heroicons/react/24/solid";
import {Modal} from "@/components/modal";
import {Dropzone} from "@/components/form/dropzone";

export function OrgProfile() {
    const [changePictureModal, setChangePictureModal] = useState(false);

    return (
        <>
            <Modal
                show={changePictureModal}
                onRequestClose={() => setChangePictureModal(false)}
                width='medium'
                title='Edit foto profil atau banner'
                body={
                    <>
                        <div className={'grid grid-cols-2 gap-2'}>
                            <div className={'w-full'}>
                                <FormLabel label='Foto profil' required>
                                    <Dropzone maxFiles={1} />
                                </FormLabel>
                            </div>
                            <div className={'w-full'}>
                                <FormLabel label='Foto banner' required>
                                    <Dropzone maxFiles={1} />
                                </FormLabel>
                            </div>
                        </div>
                    </>
                }
            />

            <div className={'text-center mx-4 mt-4 md:mt-0 md:mx-0'}>
                <div className={'bg-gradient-to-r from-purple-500 to-pink-500 h-48'} style={{backgroundImage: `url('https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2022/07/the-structure-of-a-url.webp')`}}></div>
                <div className={'-mt-12'}>
                    <Avatar className="p-overlay-badge bg-purple-500 w-32 h-32 text-4xl font-bold" label="P"
                            shape="circle" image="/images/organization/walter.jpg">
                    </Avatar>
                </div>
                <div className={'mt-2'}>
                    <span onClick={() => {
                        setChangePictureModal(true)
                    }} className={'text-sm font-semibold underline cursor-pointer'}>Edit Foto Profil / Banner</span>
                </div>
            </div>
            <div className={'md:flex justify-center mt-8 mx-4 md:mx-0'}>
                <div className={'md:w-2/3'}>
                    <div className={'grid md:grid-cols-2 gap-2'}>
                        <div className="mb-3">
                            <FormLabel label='Nama' required>
                                <Input type='text' required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Email' required>
                                <Input type='email' required/>
                            </FormLabel>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-2'}>
                        <div className="mb-3">
                            <FormLabel label='Whatsapp' required>
                                <Input leftDeco={'+62'} type='text' required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Profile Url' required>
                                <Input leftDeco={'https://tokoevent.com/'} type='text' required/>
                            </FormLabel>
                        </div>
                    </div>
                    <div className='flex justify-end gap-3'>
                        <Button
                            className={'w-full md:w-max mt-4'}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
