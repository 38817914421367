import { HSeparator, VSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import {
  CalendarDaysIcon,
  ClockIcon,
  MapPinIcon,
  TicketIcon,
} from '@heroicons/react/24/solid';
import React from 'react';

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  return <div className='flex flex-col gap-4'>{children}</div>;
};

const Row: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <div className='flex flex-col items-start gap-2 text-sm sm:flex-row'>
      <span className='font-medium text-neutral-70 sm:flex-grow'>{label}</span>
      {value}
    </div>
  );
};

export function OrgTransactionDetail() {
  return (
    <>
      <h2 className='text-center text-lg font-medium'>Detail Transaksi</h2>
      <Section>
        <Row
          label='Status pembayaran'
          value={<Tag color='warning'>Belum dibayar</Tag>}
        />
        <Row
          label='No. Invoice'
          value={<span className='text-info'>#INVTKEVNT14042023185039</span>}
        />
        <Row label='Tanggal transaksi' value='14 April 2023' />
      </Section>
      <HSeparator />
      <Section>
        <Row label='Pembeli' value='Agus Aditya' />
        <Row label='E-mail' value='agusaditya@gmail.com' />
        <Row label='No. Telepon' value='0826479186265' />
      </Section>
      <HSeparator />
      <h1 className='text-2xl font-medium'>Detail event</h1>
      <div className='flex flex-col gap-6 sm:flex-row'>
        <img
          className='h-[184px] w-full flex-shrink-0 rounded-md object-cover sm:h-[290px] sm:w-[290px]'
          src='/tmp/event-placeholder.png'
          alt=''
        />
        <div className='flex flex-grow flex-col gap-6'>
          <div className='flex flex-wrap items-start gap-2'>
            <Tag color='info'>Hybrid event</Tag>
            <Tag>Seminar</Tag>
          </div>
          <h1 className='text-xl font-semibold sm:min-h-[60px]'>
            Seminar Nasional: Bagaimana Brainsorming Film Dari Erick Est
          </h1>
          <div className='flex gap-4'>
            <div className='flex flex-grow flex-col gap-2'>
              <p className='text-sm font-medium'>Tiket terpilih</p>
              <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3'>
                <TicketIcon className='h-[20px] w-[20px] text-primary' />
                <p className='text-sm font-medium'>Reguler - Rp50.000</p>
              </div>
            </div>
            {/*  */}
            <div className='flex flex-col gap-2'>
              <p className='text-sm font-medium'>Jumlah tiket</p>
              <div className='rounded-md border-[1px] border-neutral-40 p-3'>
                <p className='text-center text-sm font-medium'>2</p>
              </div>
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <MapPinIcon className='w-[20px] shrink-0 grow-0 text-neutral-60' />
            <span className='max-line-1 h-[20px] w-[calc(80%)] shrink-0 text-sm font-medium'>
              Aula Kampus Instiki
            </span>
          </div>
          <div className='flex gap-2'>
            <div className='flex items-center gap-2'>
              <CalendarDaysIcon className='w-[20px] text-neutral-60' />
              <span className='text-sm font-medium'>17 Feb 2023</span>
            </div>
            <VSeparator />
            <div className='flex items-center gap-2'>
              <ClockIcon className='w-[20px] text-neutral-60' />
              <span className='text-sm font-medium'>08.00</span>
            </div>
          </div>
        </div>
      </div>
      <HSeparator />
      <h1 className='text-2xl font-medium'>Rincian pembayaran</h1>
      <Section>
        <Row label='Metode pembayaran' value='QRIS' />
        <Row
          label='Total harga tiket'
          value={<span className='font-medium text-danger'>Rp100.000</span>}
        />
        <Row
          label='Diskon'
          value={<span className='font-medium text-danger'>-Rp40.000</span>}
        />
        <HSeparator />
        <Row
          label={
            <span className='text-base font-medium text-neutral-100'>
              Subtotal
            </span>
          }
          value={
            <span className='text-base font-medium text-danger'>Rp60.000</span>
          }
        />
      </Section>
    </>
  );
}
