import { cn } from '@/lib/utils';
import {
  CalendarDaysIcon,
  ClockIcon,
  EyeIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import { Button } from './button';
import { VSeparator } from './separator';
import { Tag } from './tag';

export type EventCardMode = 'public' | 'private';

export interface EventCardProps extends React.HTMLAttributes<HTMLDivElement> {
  mode?: EventCardMode;
}

export const EventCard: React.FC<EventCardProps> = ({
  mode = 'public',
  className = '',
}) => {
  return (
    <div
      className={cn(
        'flex shrink-0 flex-col overflow-hidden rounded-md border-[1px] border-neutral-40 shadow-md',
        className
      )}
    >
      <img
        className='h-[268px] w-full shrink-0 grow-0 object-cover'
        src='/tmp/event-placeholder.png'
        alt=''
      />
      <div className='flex flex-col gap-4 p-4'>
        {mode !== 'public' ? (
          <div className='flex flex-wrap gap-2'>
            <Tag color='success'>Live</Tag>
            <VSeparator />
            <Tag>Paket Mahasiswa</Tag>
          </div>
        ) : undefined}
        <div className='flex flex-wrap gap-2'>
          <Tag color='info'>Hybrid event</Tag>
          <Tag>Seminar</Tag>
        </div>
        <h1 className='max-line-2 h-[60px] w-full text-xl font-semibold'>
          Seminar Nasional: Bagaimana Brainsorming Film Dari Erick Est
        </h1>
        <div className='flex items-center gap-2'>
          <MapPinIcon className='h-[16px] shrink-0 grow-0 text-neutral-60' />
          <span className='max-line-1 h-[20px] w-[calc(80%)] shrink-0 text-sm font-medium'>
            Aula Kampus Instiki
          </span>
        </div>
        <div className='flex flex-wrap gap-2'>
          <div className='flex items-center gap-2'>
            <CalendarDaysIcon className='h-[16px] text-neutral-60' />
            <span className='text-sm font-medium'>17 Feb 2023</span>
          </div>
          <VSeparator />
          <div className='flex items-center gap-2'>
            <ClockIcon className='h-[16px] text-neutral-60' />
            <span className='text-sm font-medium'>08.00</span>
          </div>
        </div>
        <Button
          element='link'
          to={mode === 'private' ? '/event/0/info' : '#'}
          leftIcon={<EyeIcon />}
        >
          Lihat detail
        </Button>
      </div>
    </div>
  );
};
