import React from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";

export function ResetPassword() {
    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Reset password? 🔑</div>
            <p className={"text-400 mb-4"}>Ganti passwordmu secara berkala untuk keamanan</p>
            <form>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Password Baru' required>
                            <Input type='Password' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Konfirmasi Password Baru' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>

                    <div>
                        <Button
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Reset Password</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
