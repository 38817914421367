import { cn } from '@/lib/utils';
import * as React from 'react';

import styles from './table.module.css';

export interface TableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  noBorder?: boolean;
  children: React.ReactNode;
}

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, children, noBorder, ...props }, ref) => {
    const tableStyle = cn(styles.table, 'w-full text-left text-xs');

    return (
      <>
        {noBorder ? (
          <table className={cn(tableStyle, className)} ref={ref} {...props}>
            {children}
          </table>
        ) : (
          <div
            className={cn(
              'rounded-md border-[1px] border-neutral-40',
              className
            )}
          >
            <table className={tableStyle} ref={ref} {...props}>
              {children}
            </table>
          </div>
        )}
      </>
    );
  }
);
Table.displayName = 'Table';
