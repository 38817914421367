import { cn } from '@/lib/utils';
import { IconComponent } from '@/lib/utils/icon';
import deco1 from './deco1.svg';
import deco2 from './deco2.svg';
import deco3 from './deco3.svg';
import deco4 from './deco4.svg';

export type DashboardCardVariant = 'yellow' | 'blue' | 'white' | 'whiteAlt';

const decoPaths: Record<DashboardCardVariant, string> = {
  yellow: deco1,
  blue: deco2,
  white: deco3,
  whiteAlt: deco4,
};

export interface LabelProps {
  icon?: IconComponent;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
}

export interface DashboardCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant: DashboardCardVariant;
  name: React.ReactNode;
  topLabel: LabelProps;
  bottomLabel?: LabelProps;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  variant,
  name,
  topLabel,
  bottomLabel,
  className,
}) => {
  const bgStyle =
    variant === 'yellow'
      ? 'bg-primary'
      : variant === 'blue'
      ? 'bg-info'
      : 'bg-neutral-10';

  const nameStyle =
    variant === 'yellow'
      ? 'text-primary-pressed'
      : variant === 'blue'
      ? 'text-neutral-10'
      : 'text-neutral-70';

  const primaryStyle =
    variant === 'blue' ? 'text-neutral-10' : 'text-neutral-100';
  const secondaryStyle =
    variant === 'blue' ? 'text-neutral-10' : 'text-neutral-70';

  const iconStyle = cn(
    variant === 'blue' ? 'text-neutral-10' : undefined,
    'inline h-5 w-5 flex-grow-0 flex-shrink-0'
  );

  return (
    <div
      className={cn(
        bgStyle,
        'relative flex h-[132px] flex-col overflow-hidden rounded-md p-4 shadow-md sm:h-[156px] sm:p-6',
        className
      )}
    >
      <img
        alt=''
        className='absolute right-0 top-0'
        src={decoPaths[variant]}
        width={53}
        height={54}
      />
      <div className='flex-grow'>
        <p className={cn(nameStyle, 'mb-2 text-xs sm:text-sm')}>{name}</p>
        <p
          className={cn(
            primaryStyle,
            'mr-2 inline text-base font-medium sm:text-2xl'
          )}
        >
          {topLabel.primary}
        </p>
        {topLabel?.secondary ? (
          <p className={cn(secondaryStyle, 'inline text-xs sm:text-sm')}>
            {topLabel?.secondary}
          </p>
        ) : undefined}
      </div>
      {bottomLabel ? (
        <div className='flex items-center gap-1'>
          {bottomLabel.icon ? (
            <bottomLabel.icon className={iconStyle} />
          ) : undefined}
          <p className={cn(primaryStyle, 'flex-grow text-xs leading-[15px]')}>
            {bottomLabel.primary}{' '}
            <span className={secondaryStyle}>{bottomLabel.secondary}</span>
          </p>
        </div>
      ) : undefined}
    </div>
  );
};
DashboardCard.displayName = 'DashboardCard';
