import { Button } from '@/components/button';
import { FormLabel } from '@/components/form/formlabel';
import { Input } from '@/components/form/input';
import { HSeparator } from '@/components/separator';
import { Table } from '@/components/table';
import {
  ArrowUpRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';

export function OrgEventDetailVouchersDetail() {
  return (
    <>
      <div className='flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:items-center'>
        <h1 className='text-lg font-medium sm:flex-grow'>Detail Voucher</h1>
        <Button color='danger' look='outline' leftIcon={<TrashIcon />}>
          Hapus voucher
        </Button>
        <Button leftIcon={<PencilSquareIcon />}>Ubah voucher</Button>
      </div>
      <div className='grid grid-cols-2 gap-5'>
        <FormLabel label='Nama voucher'>
          <Input type='text' />
        </FormLabel>
        <FormLabel label='Jumlah voucher'>
          <Input type='text' />
        </FormLabel>
      </div>
      <div className='grid grid-cols-2 gap-5'>
        <FormLabel label='Voucher aktif'>
          <Input type='text' />
        </FormLabel>
        <FormLabel label='Voucher nonaktif'>
          <Input type='text' />
        </FormLabel>
      </div>
      <HSeparator />
      <Table className='overflow-x-auto whitespace-nowrap'>
        <thead>
          <tr>
            <th>#</th>
            <th>ID Transaksi & Nama Pembeli</th>
            <th>Tiket</th>
            <th>Tanggal transaksi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              <div className='flex flex-col gap-1'>
                <div>
                  <span className='font-medium'>#TKEV1248325</span>
                  <span className='ml-2 text-info underline'>
                    <ArrowUpRightIcon className='mr-1 inline-block h-3 w-3' />
                    Lihat transaksi
                  </span>
                </div>
                <div className='font-normal text-neutral-70'>Ichsan Ghoniy</div>
              </div>
            </td>
            <td>
              <div className='flex flex-col gap-1'>
                <div>Kramat Unmas</div>
                <div>
                  <span className='text-info'>Reguler</span>{' '}
                  <span className='text-neutral-70'>(x1)</span>
                </div>
              </div>
            </td>
            <td>19/06/2023</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
