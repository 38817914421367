import {FormLabel} from "@/components/form/formlabel";
import {Button} from "@/components/button";
import {ArrowPathIcon, NoSymbolIcon} from "@heroicons/react/24/outline";
import {ChevronDownIcon, ChevronUpIcon, CreditCardIcon} from "@heroicons/react/24/solid";
import {Modal} from "@/components/modal";
import React, {useState} from "react";
import {Input} from "@/components/form/input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/form/select";
import {DropdownMenu, DropdownMenuTrigger} from "@/components/dropdown";
import qrisLogo from "@/routes/org/_payment/qris.png";
import { PaymentDetail } from "../paymentdetail";

export interface PayModalBase
    extends React.HTMLAttributes<HTMLDivElement> {
    payModal: boolean;
    setPayModal?: any;
}

export const OtsPayModal: React.FC<PayModalBase> = ({payModal = false, setPayModal}) => {
    const [detailPayment, setDetailPayment] = useState(false)

    return(
        <Modal
            show={payModal}
            onRequestClose={() => setPayModal(false)}
            title='Beli tiket'
            body={
                <div className='flex flex-col gap-4'>
                    <FormLabel
                        label='No. Whatsapp'
                        required
                    >
                        <Input leftDeco='+62' type='text' required />
                    </FormLabel>
                    <FormLabel label='Pilih tiket' required>
                        <Select>
                            <SelectTrigger>
                                <SelectValue defaultValue='others' />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value='temp1'>BCA</SelectItem>
                                    <SelectItem value='temp2'>BNI</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </FormLabel>
                    <FormLabel label='Pilih metode pembayaran'>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <div
                                    className='flex items-center gap-2 rounded-md border-[1px] border-neutral-40 p-3 text-left'>
                                    <img
                                        className='h-[36px] w-[51px] shrink-0'
                                        src={qrisLogo}
                                        alt=''
                                    />
                                    <p className='grow text-xs font-medium'>QRIS</p>
                                    <Button
                                        look='outline'
                                        size='sm'
                                        rightIcon={<ArrowPathIcon/>}
                                        className='shrink-0'
                                    >
                                        Ganti
                                    </Button>
                                </div>
                            </DropdownMenuTrigger>
                        </DropdownMenu>
                    </FormLabel>
                    <PaymentDetail />
                </div>
            }
            footer={
                <div>
                    <div className='flex justify-between gap-3'>
                        <div className={'my-auto'}>
                            <div className={'flex cursor-pointer'} onClick={() => {setDetailPayment(!detailPayment)}}>
                                <div className={'my-auto'}>
                                    <span className="text-red-500 text-md font-semibold">Rp120.000</span>
                                </div>
                                <div className={'my-auto'}>
                                    <ChevronDownIcon className={'ml-1 h-[16px] text-red-500'} />
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-3'>
                            <Button
                                variant='neutral'
                                onClick={() => setPayModal(false)}
                                leftIcon={<NoSymbolIcon />}
                            >
                                Batal
                            </Button>
                            <Button
                                onClick={() => {
                                    setPayModal(false);
                                }}
                                leftIcon={<CreditCardIcon />}
                            >
                                Bayar
                            </Button>
                        </div>
                    </div>
                    {detailPayment && <div className={"mt-4 text-left"}>
                        <div className={"mb-1"}>
                            <span className="text-900 text-md font-semibold">Subtotal: Rp0</span>
                        </div>
                        <div className={"mb-1"}>
                            <span className="text-900 text-md font-semibold">Biaya Admin: Rp0</span>
                        </div>
                        <div className={"mb-1"}>
                            <span className="text-900 text-md font-semibold">Biaya Transaksi: Rp0</span>
                        </div>
                    </div>}
                </div>
            }
        />
    )
}

OtsPayModal.displayName = 'OtsSetupModal';
