import { Breadcrumb, BreadcrumbRouteTree } from '@/components/breadcrumb';
import { Throbber } from '@/components/throbber';
import { cn } from '@/lib/utils';
import {Bars3Icon, ArrowRightStartOnRectangleIcon} from '@heroicons/react/24/outline';
import {
  BanknotesIcon,
  QrCodeIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  CircleStackIcon,
  UsersIcon,
  CogIcon
} from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SideNav } from './_nav';

import styles from './OrgLayout.module.css';

const routeTree: BreadcrumbRouteTree = {
  qrcode: {
    name: 'Scan Tiket',
    icon: QrCodeIcon,
    hidden: true,
    break: true,
  },
  dashboard: {
    name: 'Dashboard',
    icon: ChartPieIcon,
    children: {
      attendees: {
        name: 'Daftar Peserta',
      },
    },
  },
  transaction: {
    name: 'Transaksi',
    icon: CircleStackIcon,
    children: {
      '*': {
        name: 'Detail Transaksi',
      },
    },
  },
  event: {
    name: 'Event',
    icon: CalendarDaysIcon,
    children: {
      new: {
        name: 'Buat Event',
        children: {
          '*': {
            name: '',
            hidden: true,
          },
          'custom-plan': {
            name: 'Custom Paket',
          },
          checkout: {
            name: '',
            hidden: true,
          },
        },
      },
      '*': {
        name: 'Detail Event',
        children: {
          voucher: {
            name: 'Voucher',
            hidden: true,
            children: {
              '*': {
                name: 'Detail Voucher',
              },
            },
          },
        },
      },
    },
  },
  commerce: {
    name: 'Keuangan',
    icon: BanknotesIcon,
    hidden: true,
    break: true,
  },
  // committee: {
  //   name: 'Panitia',
  //   icon: UsersIcon,
  // },
  setting: {
    name: 'Pengaturan',
    icon: CogIcon,
  },
};

export function OrgLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  // const { creds, initialized, logout } = useAuth();

  const pathArray = location.pathname.substring(0).split('/').slice(1);
  const section = routeTree[pathArray[0]];

  const [loading, setLoading] = useState(false);

  const [nav, setNav] = useState(false);
  useEffect(() => setNav(false), [location]);

  // useEffect(() => {
  //   if (initialized) {
  //     console.log(creds);
  //     if (!creds.role || (creds.role && creds.role != 'organizer')) {
  //       router.push('/login');
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // }, [initialized, creds]);

  const coverStyle = cn(
    styles.coverWrapper,
    nav ? `${styles.isOpen} pointer-events-auto` : 'pointer-events-none',
    'fixed h-screen w-screen lg:hidden'
  );

  return !section ? (
    <Navigate to='dashboard' />
  ) : loading ? (
    <div className='relative h-[calc(100dvh)] w-screen'>
      <Throbber className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2' />
    </div>
  ) : (
    <div className='h-[calc(100dvh)] w-screen lg:flex'>
      <div className={coverStyle} onClick={() => setNav(false)} />

      <SideNav
        routeTree={routeTree}
        currentRoute={pathArray[0]}
        show={nav}
        onRequestClose={() => setNav(false)}
      />

      <div className='relative flex flex-shrink flex-grow flex-col overflow-x-hidden'>
        {/* Top Nav */}
        <div className='absolute left-0 right-0 top-0 z-[5] flex items-center bg-neutral-10/90 p-4 shadow-sm backdrop-blur-md lg:p-6'>
          <button
            className='rounded-[6px] border-[1px] border-neutral-40 p-[4px] lg:hidden'
            onClick={() => setNav(true)}
          >
            <Bars3Icon className='h-4 w-4' />
          </button>
          <p className='flex-grow text-center text-xl font-semibold lg:text-left'>
            {section.name}
          </p>
          <ArrowRightStartOnRectangleIcon className='h-6 w-6' />
        </div>

        <div className='flex h-[calc(100dvh)] flex-col gap-4 overflow-hidden overflow-y-auto p-4 pt-[calc(60px+16px)] lg:gap-6 lg:p-6 lg:pt-[calc(76px+16px)]'>
          {pathArray.length > 1 ? (
            <Breadcrumb
              routeTree={routeTree}
              basePath='/'
              pathArray={pathArray}
            />
          ) : undefined}

          <Outlet />
        </div>
      </div>
    </div>
  );
}
