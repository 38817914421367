import {TicketList} from "@/routes/org/event/_ticket/tickets";
import {Button} from "@/components/button";
import {PlusIcon} from "@heroicons/react/24/outline";

export function OrgTicketList() {
  return (<>
    <TicketList
    title={
      <h1 className='text-lg font-medium sm:flex-grow'>Daftar Tiket</h1>
    }
    button />
  </>);
}