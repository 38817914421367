import { cn } from '@/lib/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';

import styles from './modal.module.css';

type ModalWidth = 'small' | 'medium' | 'large' | 'fit';

const modalWidthStyles: Record<ModalWidth, string> = {
  small: 'lg:max-w-[402px]',
  medium: 'lg:max-w-[568px]',
  large: 'lg:max-w-[736px]',
  fit: 'lg:max-w-fit',
};

export interface ModalProps {
  title?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  show: boolean;
  width?: ModalWidth;
  onRequestClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  body,
  footer,
  show,
  width = 'small',
  onRequestClose,
}) => {
  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        if (onRequestClose) {
          onRequestClose();
        }
      }
    });
  }, [onRequestClose]);

  return (
    <div
      className={cn(
        styles.coverWrapper,
        show ? styles.isOpen : 'pointer-events-none',
        'fixed inset-0 z-20 flex h-[calc(100dvh)] w-screen flex-col lg:block lg:p-16'
      )}
    >
      <div
        className={cn(
          styles.modalWrapper,
          show ? styles.isOpen : undefined,
          modalWidthStyles[width],
          'mt-auto flex max-h-[calc(100%-50px)] w-full flex-col border-[1px] border-neutral-40 bg-neutral-10 lg:m-auto lg:max-h-full lg:rounded-md'
        )}
      >
        <div className='flex shrink-0 items-center border-b-[1px] border-b-neutral-40 p-4'>
          <p className='flex-grow text-base font-medium'>{title}</p>
          <button onClick={onRequestClose}>
            <XMarkIcon className='h-6 w-6' />
          </button>
        </div>
        <div className='overflow-scroll break-words p-4'>{body}</div>
        {footer ? (
          <div className='shrink-0 border-t-[1px] border-t-neutral-40 p-4'>
            {footer}
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
