import { cn } from '@/lib/utils';
import { IconComponent } from '@/lib/utils/icon';

export interface MenuItemProps {
  active: boolean;
  Icon: IconComponent;
  children: React.ReactNode;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  active,
  Icon,
  children,
}) => {
  const menuItemStyles = cn(
    active
      ? 'bg-primary'
      : 'bg-transparent hover:bg-neutral-40 active:bg-neutral-40',
    'inline-flex w-full items-center justify-start gap-2 rounded-md px-3 py-[10px] align-middle text-sm font-normal'
  );

  return (
    <div className={menuItemStyles}>
      {Icon ? <Icon className='h-[20px] text-neutral-90' /> : undefined}
      <span className='font-medium'>{children}</span>
    </div>
  );
};
MenuItem.displayName = 'MenuItem';
