import { cn } from '@/lib/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { Link } from 'react-router-dom';

export const SectionTabs: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  return (
    <div className='flex shrink-0 items-start overflow-x-auto'>
      {children}
      <div className='grow self-stretch border-b-[3px] border-neutral-40' />
    </div>
  );
};

export const SectionItem: React.FC<{
  id: string;
  name: string;
  isCurrent?: boolean;
  isDanger?: boolean;
}> = ({ id, name, isCurrent, isDanger }) => {
  return (
    <Link className='shrink-0' to={`${id}`}>
      <div
        className={cn(
          isCurrent
            ? `border-primary font-medium ${
                isDanger ? 'text-danger' : 'text-neutral-100'
              }`
            : `border-neutral-40 font-normal ${
                isDanger ? 'text-danger' : 'text-neutral-70'
              }`,
          'flex items-end gap-2 border-b-[3px] p-3 text-sm'
        )}
      >
        {isDanger ? <ExclamationTriangleIcon className='h-4 w-4' /> : undefined}
        <span>{name}</span>
      </div>
    </Link>
  );
};
