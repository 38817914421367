import { Button } from '@/components/button';
import { CreditCardIcon, DocumentCheckIcon} from '@heroicons/react/24/solid';
import {DashboardCard} from "@/routes/org/dashboard/_card";
import {NoSymbolIcon} from "@heroicons/react/24/outline";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/form/select";
import {Modal} from "@/components/modal";
import {useState} from "react";
import {Outlet} from "react-router-dom";
import {OrgOtsSystem} from "@/routes/org/event/slug/ots/payments";
import { PaymentDetail } from './payments/paymentdetail';

export function OrgOtsSystemLayout() {
  const [collateralModal, setCollateralModal] = useState(false)
  return (
    <>
      <Modal
          show={collateralModal}
          onRequestClose={() => setCollateralModal(false)}
          title='Top up dana jaminan'
          body={
            <div className='flex flex-col gap-4'>
              <FormLabel
                  label='Jumlah'
                  required
              >
                <Input leftDeco='Rp' type='text' required />
              </FormLabel>

              <FormLabel label='Pilih metode toptup' required>
                <Select>
                  <SelectTrigger>
                    <SelectValue defaultValue='others' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value='temp1'>BCA</SelectItem>
                      <SelectItem value='temp2'>BNI</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormLabel>
              <PaymentDetail />
            </div>
          }
          footer={
            <div className='flex justify-end gap-3'>
              <Button
                  variant='neutral'
                  onClick={() => setCollateralModal(false)}
                  leftIcon={<NoSymbolIcon />}
              >
                Batal
              </Button>
              <Button
                  onClick={() => {
                    setCollateralModal(false);
                  }}
                  leftIcon={<DocumentCheckIcon />}
              >
                Simpan
              </Button>
            </div>
          }
      />

      <div className='flex gap-6'>
        <DashboardCard
            variant='whiteAlt'
            name='Jaminan pembayaran cash'
            topLabel={{ primary: 'Rp 0' }}
            bottomLabel={{
              primary: (
                  <Button
                      onClick={() => setCollateralModal(true)}
                      size='sm'
                      leftIcon={<CreditCardIcon />}
                  >
                    Topup jaminan
                  </Button>
              ),
            }}
            className='w-[268px]'
        />
      </div>

      <OrgOtsSystem />
    </>
  );
}
