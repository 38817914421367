import { Button } from '@/components/button';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { idrFormatter } from '@/lib/utils/currency';
import {DocumentCheckIcon, EyeIcon} from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import {CogIcon, NoSymbolIcon, UsersIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/form/select";
import {Modal} from "@/components/modal";
import {Checkbox} from "@/components/form/checkbox";
import {OtsSetupModal} from "@/routes/org/event/slug/ots/payments/_modals/setup";
import {OtsPayModal} from "@/routes/org/event/slug/ots/payments/_modals/pay";

interface Transaction {
  id: string;
  name: string;
  date: Date;
  event: string; // FIXME: CHANGE TO PROPER EVENT INTERFACE
  ticket: string; // FIXME: CHANGE TO PROPER TICKET INTERFACE
  quantity: number;
  nominal: number;
  status: 'expired' | 'unpaid' | 'paid';
}

const columns: ColumnDef<Transaction>[] = [
  {
    header: '#',
    accessorKey: 'id',
  },
  {
    header: 'Nama',
    accessorKey: 'name',
  },
  {
    header: 'Tanggal',
    accessorFn: (row) => {
      return row.date.toLocaleString('id', { dateStyle: 'medium' });
    },
  },
  {
    header: 'Event - Tiket',
    cell: ({ row }) => {
      return (
        <>
          {row.original.event} -{' '}
          <span className='text-info'>{row.original.ticket}</span>{' '}
          <span className='text-neutral-70'>{`(x${row.original.quantity})`}</span>
        </>
      );
    },
  },
  {
    header: 'Nominal transaksi',
    cell: ({ row }) => {
      const nominal = row.original.nominal;
      return (
        <span className='text-danger'>{idrFormatter.format(nominal)}</span>
      );
    },
  },
  {
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      return status === 'expired' ? (
        <Tag color='danger'>Kedaluwarsa</Tag>
      ) : status === 'unpaid' ? (
        <Tag color='warning'>Belum bayar</Tag>
      ) : (
        <Tag color='success'>Sudah bayar</Tag>
      );
    },
  },
  {
    header: 'Aksi',
    cell: () => {
      return (
        <Button variant='neutral' size='sm' leftIcon={<EyeIcon />}>
          Tiket
        </Button>
      );
    },
  },
];

const tempData: Transaction[] = [
  {
    id: '1',
    name: 'Agung Krisna',
    date: new Date('2023-04-14T00:00:00+0800'),
    event: 'Kramat Unmas',
    ticket: 'Reguler',
    quantity: 2,
    nominal: 60000,
    status: 'unpaid',
  },
];

export function OrgOtsSystem() {
  const [setupModal, setSetupModal] = useState(false)
  const [payModal, setPayModal] = useState(false)

  return (
    <>
      <OtsSetupModal setupModal={setupModal} setSetupModal={() => {
        setSetupModal(false)
      }} />

      <OtsPayModal payModal={payModal} setPayModal={() => {
        setPayModal(false)
      }} />

      <DataTable ctaButton={
        <div className={'flex gap-2'}>
          <Button variant='neutral'
                  onClick={() => setPayModal(true)}
                  leftIcon={<UsersIcon />}>
            Tambah pembeli
          </Button>
          <Button onClick={() => setSetupModal(true)} variant='neutral' leftIcon={<CogIcon />} />
        </div>
      } name='Penjualan OTS' columns={columns} data={tempData} />
    </>
  );
}
