import React from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";

export function Login() {
    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Halo, apa kabar? 👋🏻</div>
            <p className={"text-400 mb-4"}>Login di bawah ini untuk akses akun mu</p>
            <form>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Email' required>
                            <Input type='email' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password' required>
                            <Input type='password' required/>
                        </FormLabel>
                    </div>

                    <div className="flex justify-end mb-6">
                        <a
                            href={'reset-password/send-email'}
                            className="font-medium underline ml-2 text-900 text-right cursor-pointer"
                        >
                            Lupa password?
                        </a>
                    </div>

                    <div>
                        <Button
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Masuk</Button>
                        <Button
                            variant={'neutral'}
                            look={'outline'}
                            className={'w-full mt-2'}
                            color='neutral'
                            leftIcon={<i className="pi pi-google"></i>}
                        >Masuk dengan Google</Button>
                    </div>
                </div>
            </form>
            <div className={"mt-4 text-center"}>
                <span className="text-600 font-medium line-height-3">Gak punya akun?</span>
                {' '}<a href={'register'} className={'underline font-semibold'}>Buat yuk!</a>
            </div>
        </>
    )
}
