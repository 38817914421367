import { cn } from '@/lib/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';
import { Button, ButtonProps } from './button';
import { Input } from './form/input';

export interface DataViewBaseProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  noun?: string;
  ctaButton?: React.ReactElement<ButtonProps>;
  pages?: number;
  currentPage?: number;
  onPageRequest?: (page: number) => void;
}

export const DataViewBase: React.FC<DataViewBaseProps> = ({
  name,
  noun,
  ctaButton,
  pages = 1,
  currentPage = 0,
  onPageRequest,
  children,
}) => {
  const last = useMemo(() => pages - 1, [pages]);

  const mappedIndex = useMemo<Array<number | null>>(() => {
    if (pages <= 0) {
      return [];
    }

    if (pages <= 7) {
      return Array.from(Array(pages).keys());
    }

    let mapped: Array<number | null> = [];

    mapped.push(0);

    if (currentPage - 3 <= 0) {
      mapped.push(1);
    } else {
      mapped.push(null);
    }

    const clamped = Math.min(Math.max(currentPage, 3), last - 3);
    for (let i = 0; i < 3; i++) {
      mapped.push(clamped + i - 1);
    }

    if (currentPage + 3 >= last) {
      mapped.push(last - 1);
    } else {
      mapped.push(null);
    }

    mapped.push(last);

    return mapped;
  }, [pages, currentPage, last]);

  const onPaginationClicked: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const value = +event.currentTarget.value;
    if (!isNaN(value) && onPageRequest) {
      onPageRequest(value);
    }
  };

  const onPrevClicked: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const value = currentPage - 1;
    if (value >= 0 && onPageRequest) {
      onPageRequest(value);
    }
  };

  const onNextClicked: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const value = currentPage + 1;
    if (value < pages && onPageRequest) {
      onPageRequest(value);
    }
  };

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:items-center'>
        <h1 className='text-lg font-medium sm:flex-grow'>{name}</h1>
        <div className='flex items-stretch gap-2'>
          {/*<Button variant='neutral' leftIcon={<AdjustmentsVerticalIcon />}>
            Filter
          </Button>*/}
          <Input
            type='text'
            id='search'
            placeholder='Cari sesuatu'
            className='flex-grow'
          />
        </div>
        {ctaButton}
      </div>

      {children}

      {pages && pages > 0 ? (
        <div className='flex flex-col items-center gap-2 sm:flex-row sm:flex-wrap'>
          <p className='text-sm text-neutral-70'>
            Menampilkan <span className='text-neutral-100'>5</span> dari 5{' '}
            {noun}
          </p>

          <div className='flex gap-2 sm:ml-auto'>
            <button
              className='h-7 w-7 rounded-full bg-neutral-40'
              onClick={onPrevClicked}
              disabled={currentPage === 0}
            >
              <ChevronLeftIcon className='m-auto h-5 w-5 text-neutral-90' />
            </button>

            {mappedIndex.map((v, i) => {
              return v != null ? (
                <button
                  className={cn(
                    'h-7 w-7 rounded-full text-center text-xs font-medium',
                    currentPage === v
                      ? 'bg-primary'
                      : 'border-[1px] border-neutral-40'
                  )}
                  key={i}
                  value={v}
                  onClick={onPaginationClicked}
                >
                  {v + 1}
                </button>
              ) : (
                <p key={i}>...</p>
              );
            })}

            <button
              className='h-7 w-7 rounded-full bg-neutral-40'
              onClick={onNextClicked}
              disabled={currentPage === last}
            >
              <ChevronRightIcon className='m-auto h-5 w-5 text-neutral-90' />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
DataViewBase.displayName = 'DataViewBase';
