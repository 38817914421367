// Modified from https://codepen.io/npmhieu/pen/yLGpMJj

import { cn } from '@/lib/utils';
import React from 'react';

import styles from './Throbber.module.css';

export const Throbber: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
}) => {
  return (
    <div className={cn(styles.bouncingLoader, className)}>
      <div />
      <div />
      <div />
    </div>
  );
};
Throbber.displayName = 'Throbber';
