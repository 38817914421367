import { Button } from '@/components/button';
import { Table } from '@/components/table';
import {
  EllipsisHorizontalIcon
} from '@heroicons/react/24/outline';

export interface TicketProps {
  title: React.ReactNode;
  button: React.ReactNode;
}

export const TicketList: React.FC<TicketProps> = ({title, button}) => {
  return (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex items-center'>
          {title}
          {button}
        </div>
        <Table className='overflow-scroll whitespace-nowrap md:whitespace-normal'>
          <thead>
            <tr>
              <th>#</th>
              <th>Nama tiket</th>
              <th>Harga</th>
              <th>Jumlah tiket</th>
              <th>Tgl jual</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Reguler</td>
              <td>
                <span className='text-danger'>Rp50.000</span>
              </td>
              <td>30</td>
              <td>1 April 2023 - 13 April 2023</td>
              <td>
                <Button
                  size='sm'
                  variant='neutral'
                  leftIcon={<EllipsisHorizontalIcon />}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
