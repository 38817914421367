import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import { cn } from '@/lib/utils';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

import qrisLogo from '../../_payment/qris.png';

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  return (
    <div className='flex flex-col gap-4 rounded-md border-[1px] border-info-border bg-info-surface p-4'>
      {children}
    </div>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  value: React.ReactNode;
}

const Row: React.FC<RowProps> = ({ label, value }) => {
  return (
    <div className='flex flex-col items-start gap-2 text-sm sm:flex-row sm:items-center'>
      <span className='font-medium text-neutral-70 sm:flex-grow'>{label}</span>
      {value}
    </div>
  );
};

export interface PaymentCardProps extends React.HTMLAttributes<HTMLDivElement> {
  noButton?: boolean;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  noButton,
  className,
}) => {
  const [timeLeft, setTimeLeft] = useState(3600 * 48);

  // convert timeLeft to hh:mm:ss string
  const hours = Math.floor(timeLeft / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeLeft % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');
  const timeLeftStr = `${hours} : ${minutes} : ${seconds}`;

  // decrement timeLeft every second, make sure to take account the hot reloading (using Next.js)
  useState(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div
      className={cn(
        'flex w-full flex-col gap-6 rounded-md border-[1px] border-neutral-40 p-4 lg:p-12',
        className
      )}
    >
      <p className='text-center text-lg font-medium'>
        Selesaikan pembayaran dalam waktu 48 jam
      </p>
      <p className='text-center text-3xl font-semibold text-danger'>
        {timeLeftStr}
      </p>
      <Section>
        <Row
          label='Nama paket'
          value={<span className='font-medium'>Paket Rektor</span>}
        />
        <Row
          label='Status pembayaran'
          value={<Tag color='warning'>Belum dibayar</Tag>}
        />
        <Row
          label='Metode pembayaran'
          value={
            <div className='flex items-center gap-2'>
              <img src={qrisLogo} className='rounded-md' alt='' />
              <span>QRIS</span>
            </div>
          }
        />
        <Row
          label='Nominal pembayaran'
          value={<span className='font-medium text-danger'>Rp 400.000</span>}
        />
      </Section>
      <Section>
        <p className='text-center text-sm font-medium text-neutral-70'>QRIS</p>
        <img
          src='/tmp/qr.png'
          className='h-[218px] w-[218px] self-center rounded-md border-[1px] border-neutral-40'
          alt=''
        />
      </Section>

      <HSeparator />

      {noButton ? undefined : (
        <Button element='link' to='/event' leftIcon={<CalendarDaysIcon />}>
          Kembali ke daftar event
        </Button>
      )}
    </div>
  );
};
PaymentCard.displayName = 'PaymentCard';
