import { Button } from '@/components/button';
import { Checkbox } from '@/components/form/checkbox';
import { HSeparator } from '@/components/separator';
import { cn } from '@/lib/utils';
import { idrFormatter } from '@/lib/utils/currency';
import { DocumentCheckIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// TODO: maybe pass in a PlanFeatureModel object instead of separating it in the component

interface PlanFeatureProps {
  name: string;
  priceInIdr: number;
  checked?: boolean;
  disabled?: boolean;
}

const PlanFeatureDisplay: React.FC<PlanFeatureProps> = ({
  name,
  priceInIdr,
  checked = false,
  disabled = false,
}) => {
  return (
    <div
      className={cn(
        checked
          ? 'border-primary-border bg-primary-surface'
          : 'border-neutral-40 bg-neutral-10',
        'flex items-start gap-3 rounded-md border-[1px] p-3'
      )}
    >
      <Checkbox
        className='flex-shrink-0'
        defaultChecked={checked}
        disabled={disabled}
      />
      <div className='flex flex-grow flex-col gap-2'>
        <p className='min-h-[40px] break-words text-sm font-medium'>{name}</p>
        <p className='text-xs text-info underline'>Lihat detail fitur</p>
        <HSeparator />
        <p className='text-xs font-medium text-danger'>
          +{idrFormatter.format(priceInIdr)}
        </p>
      </div>
    </div>
  );
};

export function OrgEventNewCustomPlan() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stepIdx = parseInt(searchParams.get('continue') ?? '');

  function onSaveClicked() {
    alert("Pretend that it's saved.");
    navigate(`/org/event/new/step-${!Number.isNaN(stepIdx) ? stepIdx + 1 : 1}`);
  }

  return (
    <div className='flex w-full max-w-[900px] flex-col gap-4 self-center lg:gap-6'>
      <h2 className='text-center text-lg font-medium'>Pilih fitur</h2>
      <div className='grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-6'>
        <PlanFeatureDisplay
          checked={true}
          disabled
          name='Buat event'
          priceInIdr={0}
        />
        <PlanFeatureDisplay
          checked={true}
          disabled
          name='Rekap data'
          priceInIdr={0}
        />
        <PlanFeatureDisplay
          checked={true}
          disabled
          name='Scan QR tiket peserta'
          priceInIdr={0}
        />
        <PlanFeatureDisplay name='2 tipe tiket' priceInIdr={50000} />
        <PlanFeatureDisplay name='6 tipe tiket' priceInIdr={50000} />
        <PlanFeatureDisplay
          name='Dapat melihat pembeli yang belum membayar'
          priceInIdr={50000}
        />
        <PlanFeatureDisplay name='Custom payment methods' priceInIdr={50000} />
        <PlanFeatureDisplay
          name='Event direkomendasikan paling pertama'
          priceInIdr={50000}
        />
        <PlanFeatureDisplay
          name='Dapat mengirim email kepada peserta'
          priceInIdr={50000}
        />
        <PlanFeatureDisplay name='Custom QR code absensi' priceInIdr={50000} />
        <PlanFeatureDisplay
          name='Referal code untuk panitia'
          priceInIdr={50000}
        />
      </div>
      <HSeparator />
      <div className='flex items-center'>
        <p className='grow text-lg font-semibold'>Subtotal</p>
        <p className='shrink-0 text-base font-medium text-danger'>Rp 0</p>
      </div>
      <HSeparator />
      <Button
        className='self-end'
        onClick={onSaveClicked}
        leftIcon={<DocumentCheckIcon />}
      >
        Simpan
      </Button>
    </div>
  );
}
