import { ThemeColor } from '@/lib/utils';
import clsx from 'clsx';
import React from 'react';

const colorStyles: Record<ThemeColor, string> = {
  neutral: 'border-neutral-50 bg-neutral-20 text-neutral-100',
  primary: 'border-primary-border bg-primary-surface text-primary',
  danger: 'border-danger-border bg-danger-surface text-danger',
  warning: 'border-warning-border bg-warning-surface text-warning',
  success: 'border-success-border bg-success-surface text-success',
  info: 'border-info-border bg-info-surface text-info',
};

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  color?: ThemeColor;
  children: React.ReactNode;
}

export const Tag: React.FC<TagProps> = ({
  color = 'neutral',
  children,
  className,
}) => {
  return (
    <span
      className={clsx(
        'rounded-md border-[1px] px-2 py-[2px] text-xs',
        colorStyles[color],
        className
      )}
    >
      {children}
    </span>
  );
};
Tag.displayName = 'Tag';
