import { Button } from '@/components/button';
import { HSeparator, VSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  ClockIcon,
  MapPinIcon,
  TicketIcon,
} from '@heroicons/react/24/solid';
import React from 'react';

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ label, children }) => {
  return (
    <div className='flex flex-col gap-4'>
      <h1 className='text-2xl font-semibold'>{label}</h1>
      {children}
    </div>
  );
};

export function OrgEventDetailInfo() {
  return (
    <>
      <div className='flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:items-center'>
        <h1 className='text-lg font-medium sm:flex-grow'>Detail Event</h1>
        <Button variant='danger' look='outline' leftIcon={<TrashIcon />}>
          Hapus event
        </Button>
        <Button element={'link'} to={'../edit/step-1'} leftIcon={<PencilSquareIcon />}>Ubah event</Button>
      </div>
      <div className='flex gap-6'>
        <img
          className='max-h-[447px] rounded-md'
          src='/tmp/event-placeholder.png'
          alt=''
        />
        <div className='flex shrink-0 basis-[429px] flex-col gap-5 rounded-md border-[1px] border-neutral-40 p-5'>
          <div className='flex items-start gap-2'>
            <Tag color='danger'>Offline event</Tag>
            <Tag color='neutral'>Hiburan</Tag>
          </div>
          <h1 className='max-line-1 sm:max-line-2 h-[24px] flex-shrink-0 overflow-hidden text-base font-medium sm:h-[60px] sm:text-xl sm:font-semibold'>
            Seminar Nasional: Bagaimana Brainsorming Film Dari Erick Est
          </h1>
          <HSeparator />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center'>
              <p className='mr-auto text-sm font-medium'>Pilih tiket</p>
              <a className='text-xs font-medium text-info underline' href='#'>
                Lihat daftar tiket
              </a>
            </div>
            {/* TODO: add dropdown */}
            <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3'>
              <TicketIcon className='h-[20px] w-[20px] text-primary' />
              <p className='text-sm font-medium'>Reguler - Rp50.000</p>
            </div>
            <p className='mr-auto text-sm font-medium'>Atur jumlah tiket</p>
            <div className='flex items-center gap-3'>
              <div className='flex items-start gap-1 self-start rounded-md bg-neutral-40 p-2 text-sm text-neutral-60'>
                <span className='h-[20px]'>-</span>
                <span className='w-[31px] text-center'>1</span>
                <span className='h-[20px]'>+</span>
              </div>
              <span className='text-sm font-medium text-neutral-70'>
                Tersisa <span className='text-neutral-100'>30</span> tiket
              </span>
            </div>
            <HSeparator />
            <div className='flex rounded-md border-[1px] border-primary'>
              {/* <TicketIcon className='h-[20px] w-[20px] text-primary' /> */}
              <div className='bg-primary p-3'>
                <TicketIcon className='h-[20px] w-[20px]' />
              </div>
              <div className='flex w-full p-3'>
                <p className='mr-auto text-sm font-medium'>
                  Daftar voucher tersedia
                </p>
                <ChevronRightIcon className='h-[20px] w-[20px] self-center' />
              </div>
            </div>
            <div className='flex items-center gap-1'>
              <p className='mr-auto text-base font-medium'>Subtotal</p>
              <p className='text-base font-medium text-danger'>Rp 0</p>
              <ChevronDownIcon className='h-[16px] w-[16px] self-center' />
            </div>
            <Button leftIcon={<CheckBadgeIcon />} disabled>
              Beli tiket
            </Button>
          </div>
        </div>
      </div>
      <HSeparator />
      <Section label='Detail Acara'>
        <div className='flex flex-col gap-3'>
          <div className='flex items-center gap-2'>
            <MapPinIcon className='w-[20px] shrink-0 grow-0 text-neutral-60' />
            <span className='shrink-0 text-lg font-normal'>
              Aula Kampus Instiki
            </span>
          </div>
          <div className='flex flex-wrap gap-2'>
            <div className='flex items-center gap-2'>
              <CalendarDaysIcon className='w-[20px] text-neutral-60' />
              <span className='text-lg font-normal'>17 Feb 2023</span>
            </div>
            <VSeparator />
            <div className='flex items-center gap-2'>
              <ClockIcon className='w-[20px] text-neutral-60' />
              <span className='text-lg font-normal'>08.00</span>
            </div>
          </div>
        </div>
      </Section>
      <Section label='Deskripsi'>
        <div className='text-base font-normal text-neutral-70'>
          <p>
            Kramat MMXXIII (Kreativitas Mahasiswa Teknik) merupakan suatu acara
            atau event musik yang digelar setiap tahun yang diselenggarakn oleh
            BEM FT UNMAS DPS. Di mana pada tahun ini yang mengambil tema
            &quot;ABYAKTA AKUSARA&quot; KRAMAT MMXXIII yang dilaksanakan pada
            tanggal 15 april 2023 dengan menampilkan:
          </p>
          <ul className='list-disc pl-5'>
            <li>@begundallowokwaruofficial (MALANG)</li>
            <li>@ironhead.stoned (BALI)</li>
            <li>@superchips_youngmotherhunter (MALANG)</li>
            <li>@sosbali (BALI) </li>
            <li>@draggeddeepdown (BALI) </li>
            <li>@carnagedeath (BALI) </li>
            <li>@_hikota (BALI)</li>
          </ul>
        </div>
      </Section>
      <Section label='Deskripsi'>
        <div className='text-base font-normal text-neutral-70'>
          <p>Tiket dijual</p>
          <ul className='list-disc pl-5'>
            <li>17 maret 2023</li>
          </ul>
          <br />
          <p>Batas akhir</p>
          <ul className='list-disc pl-5'>
            <li>14 april 2023</li>
          </ul>
        </div>
      </Section>
    </>
  );
}
