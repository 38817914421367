import { cn } from '@/lib/utils';
import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {}

export const Checkbox: React.FC<CheckboxProps> = ({ className, ...props }) => {
  return (
    <div className={cn('relative inline h-fit w-fit shrink-0', className)}>
      <input
        type='checkbox'
        className='peer block h-6 w-6 appearance-none rounded-md border-[1px] border-neutral-40 bg-neutral-10 checked:border-primary checked:bg-primary hover:cursor-pointer disabled:border-neutral-20 checked:disabled:border-primary-border checked:disabled:bg-primary-border'
        {...props}
      />
      <CheckIcon className='pointer-events-none absolute left-1/2 top-1/2 hidden h-5 w-5 -translate-x-1/2 -translate-y-1/2 peer-checked:block peer-disabled:text-neutral-10' />
    </div>
  );
};
