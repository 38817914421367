import { DataViewBase } from '@/components/dataview';
import { Table } from '@/components/table';

export function OrgCommercePayment() {
  return (
    <>
      <DataViewBase name='Riwayat Pemasukan' noun='transaksi'>
        {/* TODO: separate empty list graphic component */}
        {/* <div className='flex flex-col items-center gap-6 p-6'>
          <Image
            src='/static/empty-graphic.svg'
            width={300}
            height={247}
            alt=''
          />
          <div className='flex flex-col items-center gap-3'>
            <p className='text-2xl font-medium'>Belum ada event</p>
            <p className='text-center text-base text-neutral-70'>
              Saat ini belum terdapat event yang tercatat.
            </p>
          </div>
          <Button elementType='link' href='dashboard' Icon={ChartPieIcon}>
            Kembali ke dashboard
          </Button>
        </div> */}
        <Table className='overflow-x-auto whitespace-nowrap'>
          <thead>
            <tr>
              <th>#</th>
              <th>Pembeli</th>
              <th>Event - Tiket</th>
              <th>Metode pembayaran</th>
              <th>Nominal</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <div>Ichsan Ghoniy</div>
                <div className='font-normal text-neutral-70'>
                  ichsanghn@gmail.com / +62 812 7898 7636
                </div>
              </td>
              <td>
                <div>
                  Kramat Unmas - <span className='text-info'>Reguler</span>{' '}
                  <span className='text-neutral-70'>(x2)</span>
                </div>
                <div className='font-normal text-neutral-70'>
                  No. Tiket: <span className='font-medium'>TKEVNT1024853</span>
                </div>
              </td>
              <td>
                <img
                  src='/static/payment/bca.svg'
                  className='h-full rounded-md'
                  alt=''
                />
              </td>
              <td className='text-danger'>Rp60.000</td>
              <td>04/04/2023</td>
            </tr>
          </tbody>
        </Table>
      </DataViewBase>
    </>
  );
}
