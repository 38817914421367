import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { DashboardCard } from '../_card';
import { DashboardEventCard } from '../_eventcard';

interface Attendee {
  id: string;
  name: string;
  purchaseDate: Date;
  event: string;
  status: string;
  attendTime?: Date;
}

const columns: ColumnDef<Attendee>[] = [
  {
    accessorKey: 'id',
    header: '#',
  },
  {
    accessorKey: 'name',
    header: 'Nama',
  },
  {
    accessorKey: 'purchaseDate',
    header: 'Tanggal beli tiket',
    cell: ({ row }) => {
      const purchaseDate = row.original.purchaseDate;
      return (
        <>
          <span>
            {purchaseDate.toLocaleDateString('id', {
              dateStyle: 'medium',
            })}
          </span>
          <span className='ml-2 text-neutral-70'>
            {purchaseDate.toLocaleTimeString('id', {
              timeStyle: 'medium',
            })}
          </span>
        </>
      );
    },
  },
  {
    accessorKey: 'event',
    header: 'Event - Tiket',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      return (
        <Tag color={status === 'Sudah absen' ? 'success' : 'danger'}>
          {status}
        </Tag>
      );
    },
  },
  {
    accessorKey: 'attendTime',
    header: 'Absen',
    cell: ({ row }) => {
      return (
        <>
          {row.original.attendTime
            ? row.original.attendTime.toLocaleTimeString('id', {
                timeStyle: 'medium',
              })
            : '-'}
        </>
      );
    },
  },
];

const tempData: Attendee[] = [
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
  {
    id: '1',
    name: 'Agung Krisna',
    purchaseDate: new Date(),
    event: 'Kramat Unmas - Reguler (x2)',
    status: 'Sudah absen',
    attendTime: new Date(),
  },
];

export function OrgDashboardAttendeesBySlug() {
  const [page, setPage] = useState(0);

  return (
    <>
      <div className='flex shrink-0 flex-col gap-6 pb-2 sm:flex-row sm:flex-wrap sm:overflow-x-auto'>
        <DashboardEventCard showButton={false} />
        <DashboardCard
          variant='white'
          name='Daftar peserta'
          topLabel={{
            primary: '5',
            secondary: (
              <>
                dari <span className='text-neutral-100'>124</span> telah absen
              </>
            ),
          }}
          bottomLabel={{ primary: '119', secondary: 'peserta belum absen' }}
          className='w-[268px]'
        />
      </div>

      <HSeparator />

      <DataTable
        name='Daftar peserta event'
        noun='peserta'
        columns={columns}
        data={tempData}
        pages={10}
        currentPage={page}
        onPageRequest={(nextPage) => setPage(nextPage)}
        ctaButton={
          <Button variant='neutral' leftIcon={<DocumentTextIcon />}>
            Export Excel
          </Button>
        }
      />
    </>
  );
}
