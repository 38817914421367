import React from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import 'primeicons/primeicons.css';

export function AuthCreateEvent() {
    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Halo Tokoevent, <br/> buat event yuk! 🙌🏻</div>
            <p className={"text-400 mb-4"}>Buat event mu bersama kami untuk penjualan tiket yang lebih mudah</p>
            <form>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Nama Event' required>
                            <Input type='text' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Nama Penanggung Jawab' required>
                            <Input type='text' required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='No. WA' required>
                            <Input type='text' required/>
                        </FormLabel>
                    </div>

                    <div>
                        <Button
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Buat Event</Button>
                    </div>
                </div>
            </form>
            <div className={"mt-4 text-center"}>
                <span className="text-600 font-medium line-height-3">Butuh bantuan?</span>
                {' '}<a target={'_blank'} rel={'noreferrer'} href={'https://wa.me/6281238169667'}
                        className="underline font-semibold">Hubungi kami</a>
            </div>
        </>
    )
}
