import { Button } from '@/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/dropdown';
import { DataTable } from '@/components/table/datatable';
import { idrFormatter } from '@/lib/utils/currency';
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon, EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';

interface Committee {
  id: string;
  name: string;
  email: string;
  role: string;
  referral: string;
  ticketsSold: number;
  totalSold: number;
}

const columns: ColumnDef<Committee>[] = [
  {
    header: '#',
    accessorKey: 'id',
  },
  {
    header: 'Panitia',
    cell: ({ row }) => {
      const name = row.original.name;
      const email = row.original.email;
      return (
        <div>
          <p>{name}</p>
          <span className='text-gray-500'>{email}</span>
        </div>
      );
    },
  },
  {
    header: 'Role',
    accessorKey: 'role',
  },
  {
    header: 'Kode referal',
    accessorKey: 'referral',
  },
  {
    header: 'Tiket terjual',
    accessorKey: 'ticketsSold',
  },
  {
    header: 'Nominal transaksi',
    cell: ({ row }) => {
      const totalSold = row.original.totalSold;
      return (
        <span className='text-danger'>{idrFormatter.format(totalSold)}</span>
      );
    },
  },
  {
    header: 'Aksi',
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              size='sm'
              variant='neutral'
              leftIcon={<EllipsisHorizontalIcon />}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>
              <EyeIcon className='mr-2 h-4 w-4' />
              <span>Detail</span>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <PencilSquareIcon className='mr-2 h-4 w-4' />
              <span>Ubah</span>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <TrashIcon className='mr-2 h-4 w-4 text-danger' />
              <span className='text-danger'>Hapus</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

const tempData: Committee[] = [
  {
    id: '1',
    name: 'Ichsan Ghoniy',
    email: 'ichsan@tokoevent.id',
    role: 'Bendahara',
    referral: 'TK573SA',
    ticketsSold: 5,
    totalSold: 375000,
  },
];

export function OrgEventCommittee() {
  const ctaButton = <Button leftIcon={<PlusIcon />}>Tambah panitia</Button>;

  return (
    <>
      <DataTable
        name='Daftar panitia'
        ctaButton={ctaButton}
        columns={columns}
        data={tempData}
      />
    </>
  );
}
