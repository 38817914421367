import { Button } from '@/components/button';
import { Checkbox } from '@/components/form/checkbox';
import { Dropzone } from '@/components/form/dropzone';
import { FormLabel } from '@/components/form/formlabel';
import { Input } from '@/components/form/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/form/select';
import { Textarea } from '@/components/form/textarea';
import { HSeparator } from '@/components/separator';
import {
  CalendarDaysIcon,
  ClockIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

export function OrgEventNewStep1() {
  return (
    <>
      <div className='flex flex-col gap-6'>
        <FormLabel label='Poster event' required>
          <Dropzone maxFiles={1} />
        </FormLabel>

        <FormLabel label='Judul kegiatan' required>
          <Input type='text' required />
        </FormLabel>

        <div className='flex justify-stretch gap-4'>
          <FormLabel label='Pilih format acara' className='flex-grow' required>
            <Select>
              <SelectTrigger>
                <SelectValue defaultValue='others' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value='temp1'>Format 1</SelectItem>
                  <SelectItem value='temp2'>Format 2</SelectItem>
                  <SelectItem value='temp3'>Format 3</SelectItem>
                  <SelectItem value='others'>Lainnya</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormLabel>

          <FormLabel label='Pilih kategori acara' className='flex-grow'>
            <Select>
              <SelectTrigger>
                <SelectValue defaultValue='others' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value='temp1'>Kategori 1</SelectItem>
                  <SelectItem value='temp2'>Kategori 2</SelectItem>
                  <SelectItem value='temp3'>Kategori 3</SelectItem>
                  <SelectItem value='others'>Lainnya</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormLabel>
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>Waktu kegiatan</p>

        <div className='flex justify-stretch gap-4'>
          <FormLabel label='Tanggal mulai' className='flex-grow' required>
            <Input
              type='text'
              value='14/04/2023'
              rightIcon={<CalendarDaysIcon />}
              required
            />
          </FormLabel>

          <FormLabel label='Tanggal berakhir' className='flex-grow' required>
            <Input
              type='text'
              value='14/04/2023'
              rightIcon={<CalendarDaysIcon />}
              required
            />
          </FormLabel>
        </div>
        <div className='flex justify-stretch gap-4'>
          <FormLabel label='Waktu mulai' className='flex-grow' required>
            <Input
              type='text'
              value='17:00'
              rightIcon={<ClockIcon />}
              required
            />
          </FormLabel>

          <FormLabel label='Waktu berakhir' className='flex-grow' required>
            <Input
              type='text'
              value='21:00'
              rightIcon={<ClockIcon />}
              required
            />
          </FormLabel>
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>Lokasi kegiatan</p>

        <FormLabel label='Kegiatan online' inline>
          <Checkbox />
        </FormLabel>

        <div className='flex flex-col gap-6 lg:flex-row lg:justify-stretch lg:gap-4'>
          <FormLabel label='Tempat' className='flex-grow' required>
            <Input type='text' required />
          </FormLabel>

          <FormLabel label='Link Google Maps' className='flex-grow' required>
            <Input type='text' required />
          </FormLabel>
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>
          Deskripsi, syarat dan ketentuan
        </p>

        {/* TODO: add back RTF support */}
        {/* <RichTextInput label='Deskripsi kegiatan' required /> */}
        {/* <RichTextInput label='Syarat dan ketentuan' required /> */}

        <FormLabel label='Deskripsi kegiatan' required>
          <Textarea required />
        </FormLabel>

        <FormLabel label='Syarat dan ketentuan' required>
          <Textarea required />
        </FormLabel>
      </div>
    </>
  );
}
