import { Button } from '@/components/button';
import { DataViewBase } from '@/components/dataview';
import { EventCard } from '@/components/eventcard';
import { Modal } from '@/components/modal';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function OrgEvent() {
  const navigate = useNavigate();

  const [draftWarnModal, setDraftWarnModal] = useState(false);

  const ctaButton = (
    <Button onClick={onNewEventClicked} leftIcon={<PlusIcon />}>
      Buat event baru
    </Button>
  );

  function onNewEventClicked() {
    // Simulates if there's an event draft
    if (true) {
      setDraftWarnModal(true);
    } else {
      createEvent()
    }
  }

  function createEvent() {
    navigate('0/edit/step-1');
  }

  return (
    <>
      <Modal
        show={draftWarnModal}
        onRequestClose={() => setDraftWarnModal(false)}
        width='fit'
        title='Peringatan!'
        body={
          <>
            Anda memiliki draft yang belum dipublikasi. Membuat event baru akan
            menghapus draft tersebut. Yakin ingin melanjutkan?
          </>
        }
        footer={
          <div className='flex justify-end gap-3'>
            <Button
              variant='neutral'
              onClick={() => setDraftWarnModal(false)}
              leftIcon={<XMarkIcon />}
            >
              Tidak
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                setDraftWarnModal(false);
                createEvent()
              }}
              leftIcon={<TrashIcon />}
            >
              Iya
            </Button>
          </div>
        }
      />
      <div>
        <DataViewBase name='Daftar Event' noun='event' ctaButton={ctaButton}>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'>
            <EventCard mode='private' />
            <EventCard mode='private' />
            <EventCard mode='private' />
            <EventCard mode='private' />
            <EventCard mode='private' />
            <EventCard mode='private' />
            <EventCard mode='private' />
          </div>
        </DataViewBase>
      </div>
    </>
  );
}
