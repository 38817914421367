import { Button } from '@/components/button';
import { Checkbox } from '@/components/form/checkbox';
import { FormLabel } from '@/components/form/formlabel';
import { Modal } from '@/components/modal';
import { HSeparator } from '@/components/separator';
import { Table } from '@/components/table';
import {
  CalendarDaysIcon,
  EllipsisHorizontalIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import { NewEventFlowContext } from '../state';
import {DocumentCheckIcon} from "@heroicons/react/24/solid";
import {Input} from "@/components/form/input";
import {TicketList} from "@/routes/org/event/_ticket/tickets";

export function OrgEventNewStep2() {
  const { setPlanModal } = useContext(NewEventFlowContext);

  const [ticketModal, setTicketModal] = useState({
    state: false,
    label: '',
  });

  function onAddTicketClicked() {
    setTicketModal({
      state: true,
      label: 'create'
    });
  }

  function onEditTicketClicked() {
    setTicketModal({
      state: true,
      label: 'edit'
    });
  }

  return (
    <>
      <Modal
        show={ticketModal.state}
        onRequestClose={() => setTicketModal({
          state: false,
          label: ''
        })}
        title={ticketModal.label === 'edit' ? 'Edit tiket' : 'Tambah tiket'}
        body={
          <>
            <div className={'mb-3'}>
              <FormLabel label='Nama tiket' required>
                <Input type='text' required />
              </FormLabel>
            </div>
            <div className='mb-3 flex justify-stretch gap-2'>
              <FormLabel
                  label='Harga tiket'
                  required
              >
                <Input leftDeco='Rp' type='text' required />
              </FormLabel>
              <FormLabel
                  label='Jumlah tiket'
                  required
              >
                <Input type='text' required />
              </FormLabel>
            </div>
            <div className='mb-3 flex justify-stretch gap-2'>
              <FormLabel label='Tanggal mulai dijual' className='flex-grow' required>
                <Input
                    type='text'
                    value='14/04/2023'
                    rightIcon={<CalendarDaysIcon />}
                    required
                />
              </FormLabel>
              <FormLabel label='Tanggal berakhir dijual' className='flex-grow' required>
                <Input
                    type='text'
                    value='14/04/2023'
                    rightIcon={<CalendarDaysIcon />}
                    required
                />
              </FormLabel>
            </div>
          </>
        }
        footer={
          <div className='flex justify-end gap-3'>
            <Button
              variant='neutral'
              onClick={() => setTicketModal({
                state: false,
                label: ''
              })}
              leftIcon={<XMarkIcon />}
            >
              Tutup
            </Button>
            <Button
              onClick={() => {
                setTicketModal({
                  state: false,
                  label: ''
                });
                if (setPlanModal) {
                  setPlanModal(true);
                }
              }}
              leftIcon={<DocumentCheckIcon />}
            >
              Simpan
            </Button>
          </div>
        }
      />

      <TicketList
      title={
        <p className='flex-grow text-base font-medium text-danger'>
        Daftar tiket
      </p>
      }
      button={
        <Button
            size='sm'
            look='outline'
            onClick={onAddTicketClicked}
            leftIcon={<PlusIcon />}
        >
          Tambah tiket baru
        </Button>
      } />

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>Metode pembayaran</p>
        <div className='flex flex-col gap-3'>
          <p className='text-sm font-medium text-neutral-70'>E-money</p>
          <div className='flex flex-wrap gap-6'>
            <FormLabel label='Dana' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>

            <FormLabel label='GoPay' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>

            <FormLabel label='ShopeePay' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <p className='text-sm font-medium text-neutral-70'>Virtual account</p>
          <div className='flex flex-wrap gap-6'>
            <FormLabel label='BCA' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>

            <FormLabel label='BRI' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>

            <FormLabel label='Mandiri' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <p className='text-sm font-medium text-neutral-70'>QRIS</p>
          <div className='flex flex-wrap gap-6'>
            <FormLabel label='QRIS' inline>
              <Checkbox checked={true} disabled />
            </FormLabel>
          </div>
        </div>
      </div>
    </>
  );
}
