import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Button } from '../button';

interface DropzoneProps
  extends Pick<
    DropzoneOptions,
    'accept' | 'minSize' | 'maxSize' | 'maxFiles' | 'disabled'
  > {
  onChange?: (files: File[]) => void;
}

export const Dropzone: React.FC<DropzoneProps> = ({ onChange, ...props }) => {
  const [previewFileUrls, setPreviewFileUrls] = useState<string[]>([]);

  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    ...props,
    onDrop: (acceptedFiles) => {
      const newUrls = acceptedFiles.map((file) => URL.createObjectURL(file));
      setPreviewFileUrls(newUrls);
    },
  });
  const thumbs = previewFileUrls.map((url) => {
    return (
      <img
        className='h-[150px] max-w-full rounded-md object-cover'
        src={url}
        alt=''
      />
    );
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => previewFileUrls.forEach((url) => URL.revokeObjectURL(url));
  }, []);

  return (
    <div
      {...getRootProps({
        className:
          'flex flex-col items-center gap-4 rounded-md border-[1px] border-neutral-40 bg-neutral-20 p-4',
      })}
    >
      <input {...getInputProps()} />
      {thumbs}
      <p className='text-xs text-neutral-70'>
        Maksimal ukuran file 2MB (Format JPG, PNG)
      </p>
      <div className='flex gap-3'>
        {previewFileUrls.length > 0 && <Button
          size='sm'
          variant='danger'
          look='outline'
          element='button'
          // onClick={removeFile}
          onClick={(event) => {
            event.stopPropagation();
          }}
          leftIcon={<TrashIcon />}
        >
          Hapus Gambar
        </Button>}
        {previewFileUrls.length === 0 && <Button
          size='sm'
          element='button'
          onClick={(event) => {
            event.stopPropagation();
            open();
          }}
          leftIcon={<ArrowUpTrayIcon />}
        >
          Unggah gambar
        </Button>}
      </div>
    </div>
  );
};
